import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const options = {
  key: 'store',
  paths: ['user', 'token'],
}

if (process.env.NODE_ENV === 'production') {
  const ls = new SecureLS({ isCompression: false })
  options.storage = {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  }
}

const store = new Vuex.Store({
  state: {
    user: null,
    token: null,
    print: false,
    pdf: false,
    valueDiff: false,
    tests: null,
    tabActive: 'single',
    historyMinDate: null,
    historyMaxDate: null,
  },
  mutations: {
    auth(st, {
      user, token,
    }) {
      st.token = token
      st.user = user
    },
    updateUser(st, { user }) {
      st.user = user
    },
    setPrint(st, payload) {
      st.print = payload
    },
    setPdf(st, payload) {
      st.pdf = payload
    },
    setValueDiff(st, payload) {
      st.valueDiff = payload
    },
    setTests(st, payload) {
      st.tests = payload
    },
    setTabActive(st, payload) {
      st.tabActive = payload
    },
    setHistoryDate(st, payload) {
      st.historyMinDate = payload.min
      st.historyMaxDate = payload.max
    },
  },
  plugins: [createPersistedState(options)],
  strict: debug,
})

export default store
