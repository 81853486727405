<template>
  <div
    :class="['delete-guide', { 'delete-guide-active': open }]"
    @click="close($event)"
  >
    <div class="delete-guide-content">
      <div class="delete-guide-content-header">
        You are about to delete this {{ guide ? guide.name : '' }} breath pacer model preset. To confirm that you are certain to delete it, click YES, DELETE IT.
      </div>
      <div class="delete-guide-content-nav">
        <Button
          :title="'Delete it'"
          :width="150"
          :height="38"
          @click="deleteGuideHandler"
        ></Button>
        <Button
          :title="'Don’t delete'"
          :width="150"
          :height="38"
          :margin="0"
          :mode="'cancel'"
          @click="$emit('deleteController')"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>

  import Button from '@/components/Button/Button'
  import { deleteCaregiverGuide } from '../../utilites/api'

  import './DeleteGuide.scss'

  export default {
    components: { Button },
    props: ['open', 'token', 'guide'],
    methods: {
      deleteGuideHandler() {
        deleteCaregiverGuide(this.token, { id: this.guide.id })
          .then((res) => {
            this.$emit('deleteGuide', this.guide.id)
            this.$emit('deleteController')
          })
      },
      close(event) {
        if (event.target.classList.contains('delete-guide-active') || event.target.classList.contains('delete')) {
          this.$emit('deleteController')
        }
      },
    },
  }
</script>
