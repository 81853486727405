<template>
  <header :class="['wrapper-header',{ 'wrapper-header-close': !isOpenMobile }]">
    <div
      class="wrapper-header-btn"
      @click="$emit('openClient')"
    >
      <OpenIcon></OpenIcon> <span>Open client</span>
    </div>
    <router-link
      to="/add-client"
      class="wrapper-header-btn"
    >
      <AddIcon></AddIcon> <span>Add client</span>
    </router-link>
    <div
      class="wrapper-header-btn"
      @click="$emit('openInvite')"
    >
      <InviteIcon></InviteIcon> <span>Invite client</span>
    </div>
    <div
      :class="['wrapper-header-btn wrapper-header-btn-search', { 'wrapper-header-btn-active': isOpenSearch }]"
      @click="isOpenSearch = !isOpenSearch"
    >
      <SearchIcon></SearchIcon> <span>Search client</span>
      <div
        :class="['wrapper-header-btn-search', { 'wrapper-header-btn-search-active': isOpenSearch }]"
        @click.prevent.stop
      >
        <input
          type="text"
          placeholder="Search client"
          :value="search"
          @input="search = $event.target.value"
        >
        <SearchIcon></SearchIcon>
      </div>
    </div>
    <router-link
      to="/account"
      class="wrapper-header-btn"
    >
      <AccountIcon></AccountIcon> <span>Account</span>
    </router-link>
    <router-link
      to="/configuration"
      class="wrapper-header-btn"
    >
      <ConfigIcon></ConfigIcon> <span>Configuration</span>
    </router-link>
    <div class="wrapper-header-btn">
      <HelpIcon></HelpIcon> <span>Help</span>
    </div>
    <div
      class="wrapper-header-btn"
      @click="$emit('logoutController')"
    >
      <LogoutIcon></LogoutIcon> <span>Log out</span>
    </div>
  </header>
</template>

<script>

  import OpenIcon from '../../assets/img/header/folder.svg'
  import AddIcon from '../../assets/img/header/add.svg'
  import InviteIcon from '../../assets/img/header/plane.svg'
  import SearchIcon from '../../assets/img/header/search.svg'
  import AccountIcon from '../../assets/img/header/account.svg'
  import ConfigIcon from '../../assets/img/header/setting.svg'
  import HelpIcon from '../../assets/img/header/question.svg'
  import LogoutIcon from '../../assets/img/header/logout.svg'

  import './Header.scss'

  export default {
    components: {
      OpenIcon,
      AddIcon,
      InviteIcon,
      SearchIcon,
      AccountIcon,
      ConfigIcon,
      HelpIcon,
      LogoutIcon,
    },
    props: {
      isOpenMobile: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isOpenSearch: false,
        search: '',
      }
    },
    watch: {
      isOpenSearch() {
        this.search = ''
      },
      search() {
        this.$emit('userSearch', this.search)
      },
    },
  }
</script>
