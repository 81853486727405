<template>
  <div class="history">
    <div
      class="history-type"
    >
      <span style="width: auto;">Health History</span>
    </div>
    <div class="history-legend">
      <div class="history-legend-param">
        <IconFirst></IconFirst>
        <Dropdown
          v-model="paramOne"
          :options="paramsDropdown"
          :close-on-outside-click="true"
        >
        </Dropdown>
        <template v-if="paramOne.key != null">
          <IconTrendUp v-if="trend[0] === 'up'"></IconTrendUp>
          <IconTrendDown v-if="trend[0] === 'down'"></IconTrendDown>
        </template>
      </div>
      <div class="history-legend-param">
        <template v-if="paramTwo.key != null">
          <IconTrendUp v-if="trend[1] === 'up'"></IconTrendUp>
          <IconTrendDown v-if="trend[1] === 'down'"></IconTrendDown>
        </template>
        <Dropdown
          v-model="paramTwo"
          :right="true"
          :options="paramsDropdown"
          :close-on-outside-click="true"
        >
        </Dropdown>
        <IconSecond></IconSecond>
      </div>
    </div>
    <div class="history-apex">
      <apex
        ref="chart"
        :height="apexHeight"
        :options="chartOptions"
        :series="series"
      ></apex>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import VueApexCharts from 'vue-apexcharts'
  import Dropdown from '@/components/Dropdown/Dropdown'

  import Arrow from '@/assets/img/arrow_test.svg'
  import IconFirst from '@/assets/img/history/first.svg'
  import IconSecond from '@/assets/img/history/second.svg'

  import IconTrendUp from '@/assets/img/history/up.svg'
  import IconTrendDown from '@/assets/img/history/down.svg'

  import { getUser } from '@/utilites/api'

  import './UserHistory.scss'

  function getAvg(arr) {
    const total = arr.reduce((acc, c) => acc + c, 0)
    return total / arr.length
  }

  function getSum(arr) {
    return arr.reduce((acc, c) => acc + c, 0)
  }

  function trend(data, xKey, yKey) {
    const xData = data.map((value) => value[xKey])
    const yData = data.map((value) => value[yKey])

    // average of X values and Y values
    const xMean = getAvg(xData)
    const yMean = getAvg(yData)

    // Subtract X or Y mean from corresponding axis value
    const xMinusxMean = xData.map((val) => val - xMean)
    const yMinusyMean = yData.map((val) => val - yMean)

    // eslint-disable-next-line no-restricted-properties
    const xMinusxMeanSq = xMinusxMean.map((val) => Math.pow(val, 2))

    const xy = []
    for (let x = 0; x < data.length; x += 1) {
      xy.push(xMinusxMean[x] * yMinusyMean[x])
    }

    // const xy = xMinusxMean.map((val, index) => val * yMinusyMean[index]);

    const xySum = getSum(xy)

    // b1 is the slope
    const b1 = xySum / getSum(xMinusxMeanSq)
    // b0 is the start of the slope on the Y axis
    const b0 = yMean - b1 * xMean

    return {
      slope: b1,
      yStart: b0,
      calcY: (x) => b0 + b1 * x,
    }
  }

  export default {
    components: {
      apex: VueApexCharts,
      Dropdown,
      Arrow,
      IconFirst,
      IconSecond,
      IconTrendUp,
      IconTrendDown,
    },
    data() {
      return {
        windowHeight: window.innerHeight,
        error: true,
        errorMessage: '',
        loading: false,
        minDate: undefined,
        maxDate: undefined,
        minOne: undefined,
        maxOne: undefined,
        minTwo: undefined,
        maxTwo: undefined,
        typesList: [
          'single',
          'cardiac',
          'breatwork',
        ],
        paramOne: {
          key: null,
          label: 'None',
          type: 'single',
        },
        paramTwo: {
          key: null,
          label: 'None',
          type: 'single',
        },
        paramsList: [
          'null',
          'SI',
          'SDNN',
          'RMSSD',
          'AutonomicBalance',
          'HeartRate',
          'SpO2',
          'SL',
          'FR',
          'HSI',
          'MeanScore',
          'Consistency',
          'HrVariation',
          'MeanHR',
          'AgingSpeed',
          'BioAge',
          'CalAge',
          'Weight',
          'BMI',
          'Systolic',
          'Diastolic',
          'Blood glucose',
        ],
        params: {
          null: 'None',
          SI: 'Stress Index',
          SDNN: 'HRV Index',
          RMSSD: 'Vagal Index',
          AutonomicBalance: 'Autonomic Balance',
          HeartRate: 'Resting HR',
          SpO2: 'SpO2',
          SL: 'Adaptation Strain',
          FR: 'Adaptation Resource',
          HSI: 'Adaptation Index',
          MeanScore: 'Resonance',
          Consistency: 'Congruence',
          HrVariation: 'HR Variation',
          MeanHR: 'Mean HR',
          AgingSpeed: 'Aging Speed',
          BioAge: 'Biological Age',
          CalAge: 'Calendar Age',
          Weight: 'Weight',
          BMI: 'BMI',
          Systolic: 'Systolic BP',
          Diastolic: 'Diastolic BP',
          'Blood glucose': 'Blood Glucose',
        },
        paramsSettings: {
          null: { type: 'null' },
          SI: { type: 'single' },
          SDNN: { type: 'single' },
          RMSSD: { type: 'single' },
          AutonomicBalance: { type: 'single' },
          HeartRate: { type: 'single' },
          SpO2: { type: 'single' },
          SL: { type: 'single' },
          FR: { type: 'single' },
          HSI: { type: 'single' },
          MeanScore: { type: 'cardiac' },
          Consistency: { type: 'cardiac' },
          HrVariation: { type: 'cardiac' },
          MeanHR: { type: 'single' },
          AgingSpeed: { type: 'cardiac' },
          BioAge: { type: 'cardiac' },
          CalAge: { type: 'cardiac' },
          Weight: { type: 'single' },
          BMI: { type: 'single' },
          Systolic: { type: 'single', min: 0, max: 200 },
          Diastolic: { type: 'single', min: 0, max: 200 },
          'Blood glucose': { type: 'single' },
        },
      }
    },
    computed: {
      ...mapState(['user', 'token', 'print', 'tests']),
      apexHeight() {
        if (this.print) return undefined
        const seed = this.windowHeight - 308
        if (seed < 100) return '100px'
        return `${seed}px`
      },
      data() {
        const unitList = []
        if (this.tests == null) return []

        this.typesList.forEach((type) => {
          if (this.tests[type] == null) return
          unitList.push(...this.tests[type])
        })

        return unitList
      },
      paramsDropdown() {
        return this.paramsList.map((p) => ({ key: p, label: this.params[p] }))
      },
      series() {
        const series = []

        const dataOne = []
        const dataTwo = []

        const pKeyOne = this.paramOne.key
        const pKeyTwo = this.paramTwo.key

        this.data.forEach((t) => {
          if (pKeyOne != null) {
            if (pKeyOne === 'Weight' || pKeyOne === 'BMI') {
              if (t.compose != null && t.compose.data != null && t.compose.data[pKeyOne] != null) {
                dataOne.unshift([t.date * 1000, t.compose.data[pKeyOne]])
              }
            } else if (pKeyOne === 'Systolic' || pKeyOne === 'Diastolic') {
              if (t.pressure != null && t.pressure.data != null && t.pressure.data[pKeyOne] != null) {
                dataOne.unshift([t.date * 1000, t.pressure.data[pKeyOne]])
              }
            } else if (pKeyOne === 'Blood glucose') {
              if (t.glucose != null && t.glucose.data != null && t.glucose.data[pKeyOne] != null) {
                dataOne.unshift([t.date * 1000, t.glucose.data[pKeyOne]])
              }
            } else if (t.data[pKeyOne] != null) {
              dataOne.unshift([t.date * 1000, t.data[pKeyOne]])
            }
          }
          if (pKeyTwo != null) {
            if (pKeyTwo === 'Weight' || pKeyTwo === 'BMI') {
              if (t.compose != null && t.compose.data != null && t.compose.data[pKeyTwo] != null) {
                dataTwo.unshift([t.date * 1000, t.compose.data[pKeyTwo]])
              }
            } else if (pKeyTwo === 'Blood glucose') {
              if (t.glucose != null && t.glucose.data != null && t.glucose.data[pKeyTwo] != null) {
                dataOne.unshift([t.date * 1000, t.glucose.data[pKeyTwo]])
              }
            } else if (pKeyTwo === 'Systolic' || pKeyTwo === 'Diastolic') {
              if (t.pressure != null && t.pressure.data != null && t.pressure.data[pKeyTwo] != null) {
                dataOne.unshift([t.date * 1000, t.pressure.data[pKeyTwo]])
              }
            } else if (t.data[pKeyTwo] != null) {
              dataTwo.unshift([t.date * 1000, t.data[pKeyTwo]])
            }
          }
        })

        if (dataOne.length > 0) {
          const dateOne = dataOne.map((data) => data[0])
          const xOneMin = Math.min(...dateOne)
          const xOneMax = Math.max(...dateOne)
          const trendOne = trend(dataOne, '0', '1')

          series.push({
            name: 's-1-pseudo',
            type: 'scatter',
            data: dataOne,
          })

          series.push({
            name: 's-1',
            type: 'line',
            data: [
              [xOneMin, trendOne.calcY(xOneMin)],
              [xOneMax, trendOne.calcY(xOneMax)],
            ],
          })
        } else {
          series.push({
            name: 's-1-pseudo',
            type: 'scatter',
            data: [],
          })

          series.push({
            name: 's-1',
            type: 'line',
            data: [],
          })
        }

        if (dataTwo.length > 0) {
          const dateTwo = dataTwo.map((data) => data[0])
          const xTwoMin = Math.min(...dateTwo)
          const xTwoMax = Math.max(...dateTwo)
          const trendTwo = trend(dataTwo, '0', '1')

          series.push({
            name: 's-2-pseudo',
            type: 'scatter',
            data: dataTwo,
          })

          series.push({
            name: 's-2',
            type: 'line',
            data: [
              [xTwoMin, trendTwo.calcY(xTwoMin)],
              [xTwoMax, trendTwo.calcY(xTwoMax)],
            ],
          })
        }

        return series
      },
      trend() {
        if (this.series.length < 4) return [null, null]
        if (
          this.series[1].data == null
          || this.series[1].data[0] == null
          || this.series[1].data[0][1] == null
          || this.series[1].data[1] == null
          || this.series[1].data[1][1] == null
          || this.series[3].data == null
          || this.series[3].data[0] == null
          || this.series[3].data[0][1] == null
          || this.series[3].data[1] == null
          || this.series[3].data[1][1] == null
        ) {
          return [null, null]
        }

        const first = this.series[1].data[0][1] < this.series[1].data[1][1] ? 'up' : 'down'
        const second = this.series[3].data[0][1] < this.series[3].data[1][1] ? 'up' : 'down'

        return [first, second]
      },
      chartOptions() {
        let foreColor = '#ffffff'
        if (this.print) {
          foreColor = 'black'
        }

        return {
          markers: {
            shape: ['circle', null, 'square', null],
            size: [4.5, 0, 1, 0],
            fillOpacity: [0, 0, 0, 0],
            strokeWidth: [1, 1, 1, 1],
            strokeColors: ['#0CABFC', '#0CABFC', '#02AE39', '#02AE39'],
            radius: [0],
          },
          chart: {
            animations: {
              enabled: !this.print,
            },
            type: 'line',
            toolbar: {
              show: false,
            },
            foreColor,
            width: '100%',
            zoom: {
              enabled: false,
            },
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [2, 2, 2, 2],
            lineCap: 'round',
            colors: ['#0CABFC', '#0CABFC', '#02AE39', '#02AE39'],
            dashArray: [5, 5, 5, 5],
          },
          tooltip: {
            enabled: false,
            // x: {
            //   format: 'MM/dd/yyyy',
            // },
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: 'datetime',
            min: this.minDate,
            max: this.maxDate,
            labels: {
              format: 'MM/dd',
            },
            axisTicks: {
              show: true,
              borderType: 'solid',
            },
          },
          yaxis: [
            {
              axisBorder: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
              min: this.minOne,
              max: this.maxOne,
              labels: {
                offsetX: -10,
                style: {
                  colors: '#0CABFC',
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                formatter: (value) => Math.round(value),
              },
            },
            {
              show: false,
              min: this.minOne,
              max: this.maxOne,
              seriesName: 's-1',
            },
            {
              seriesName: 's-2-pseudo',
              opposite: true,
              axisBorder: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
              min: this.minTwo,
              max: this.maxTwo,
              tickAmount: 5,
              labels: {
                offsetX: -10,
                style: {
                  colors: '#02AE39',
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                formatter: (value) => Math.round(value),
              },
            },
            {
              seriesName: 's-2',
              opposite: true,
              min: this.minTwo,
              max: this.maxTwo,
              tickAmount: 5,
              show: false,
            },
          ],
          grid: {
            borderColor: '#6e6e6e',
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
        }
      },
    },
    watch: {
      series() {
        // calculate report date for export

        let seedMax = Date.now()
        let seedMin = seedMax - 30 * 24 * 60 * 60 * 1000

        let maxDateOne = null
        let minDateOne = null
        if (this.series[0]) {
          minDateOne = Math.min(...this.series[0].data.map((d) => d[0]))
          maxDateOne = Math.max(...this.series[0].data.map((d) => d[0]))
        }

        let maxDateTwo = null
        let minDateTwo = null
        if (this.series[2]) {
          minDateTwo = Math.min(...this.series[2].data.map((d) => d[0]))
          maxDateTwo = Math.max(...this.series[2].data.map((d) => d[0]))
        }

        if (minDateOne == null && minDateTwo == null) {
          // pass
        } else if (minDateOne == null) {
          seedMin = minDateTwo
        } else if (minDateTwo == null) {
          seedMin = minDateOne
        } else {
          seedMin = Math.min(minDateOne, minDateTwo)
        }

        if (maxDateOne == null && maxDateTwo == null) {
          // pass
        } else if (maxDateOne == null) {
          seedMax = maxDateTwo
        } else if (maxDateTwo == null) {
          seedMax = maxDateOne
        } else {
          seedMax = Math.max(maxDateOne, maxDateTwo)
        }

        let interval = Math.floor((seedMax - seedMin) / (1000 * 60 * 60 * 24))
        interval = Math.ceil(interval % 30)
        interval = Math.floor((30 - interval) / 2)

        this.minDate = seedMin - interval * 1000 * 60 * 60 * 24
        this.maxDate = seedMax + interval * 1000 * 60 * 60 * 24

        this.setHistoryDate({
          min: new Date(seedMin),
          max: new Date(seedMax),
        })

        // values
        let maxOne
        let minOne
        if (this.paramsSettings[this.paramOne.key] != null
          && this.paramsSettings[this.paramOne.key].min != null
          && this.paramsSettings[this.paramOne.key].max != null) {
          minOne = this.paramsSettings[this.paramOne.key].min
          maxOne = this.paramsSettings[this.paramOne.key].max
        } else if (this.series[0]) {
          minOne = Math.min(...this.series[0].data.map((d) => d[1]))
          minOne = Math.floor((minOne - 1) / 10) * 10 - 10
          maxOne = Math.max(...this.series[0].data.map((d) => d[1]))
          maxOne = Math.ceil((maxOne + 1) / 10) * 10 + 10
        }
        this.minOne = minOne
        this.maxOne = maxOne

        let maxTwo
        let minTwo
        if (this.paramsSettings[this.paramTwo.key] != null
          && this.paramsSettings[this.paramTwo.key].min != null
          && this.paramsSettings[this.paramTwo.key].max != null) {
          minTwo = this.paramsSettings[this.paramTwo.key].min
          maxTwo = this.paramsSettings[this.paramTwo.key].max
        } else if (this.series[0]) {
          minTwo = Math.min(...this.series[2].data.map((d) => d[1]))
          minTwo = Math.floor((minTwo - 1) / 10) * 10 - 10
          maxTwo = Math.max(...this.series[2].data.map((d) => d[1]))
          maxTwo = Math.ceil((maxTwo + 1) / 10) * 10 + 10
        }

        this.minTwo = minTwo
        this.maxTwo = maxTwo
      },
    },
    mounted() {
      this.paramOne = {
        key: 'SL',
        label: 'Adaptation Strain',
      }

      this.paramTwo = {
        key: 'SDNN',
        label: 'HRV Index',
      }

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize)
      })
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      ...mapMutations(['setHistoryDate']),
      onResize() {
        this.windowHeight = window.innerHeight
      },
      clientData() {
        this.loading = true
        getUser(this.token, { id: this.$route.params.id })
          .then((res) => {
            if (res.errors) {
              this.pushController(res.errors.pop().message)
            } else if (res.data.clientData.nodes[0]) {
              this.readTest(res.data.clientData.nodes)
            }
          }).finally(() => {
            this.loading = false
          })
      },
      randomParam(except = null) {
        let key = this.paramsList[Math.floor(Math.random() * this.paramsList.length)]
        while (key == null || (except != null && key === except)) {
          key = this.paramsList[Math.floor(Math.random() * this.paramsList.length)]
        }
        return {
          key,
          label: this.params[key],
        }
      },
    },
  }
</script>
