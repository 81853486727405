<template>
  <div
    :class="['input', { 'input-active': active }]"
    :style="{ marginBottom: marginBottom + 'px' }"
  >
    <input
      :type="typeInput"
      :style="{ display: 'none' }"
      :name="name"
    >
    <input
      :type="typeInput"
      :name="name"
      :value="value"
      :readonly="readonly"
      class="input-inner"
      autocomplete="new-password"
      @input="update($event.target.value)"
      @focus="focus($event)"
      @blur="blur($event)"
    >
    <div
      :class="['input-placeholder', { 'input-placeholder-active': active || alwaysActive, 'input-placeholder-gray': alwaysActive }]"
      @click.stop="$event.target.previousElementSibling.focus()"
    >
      <div
        class="input-placeholder-name"
        @click.stop="$event.target.parentElement.previousElementSibling.focus()"
      >
        {{ placeholder }} <span @click.stop="$event.target.parentElement.parentElement.previousElementSibling.focus()">{{ required ? '*' : '' }}</span>
      </div>
      <span
        class="input-placeholder-hint"
        @click.stop="$event.target.parentElement.previousElementSibling.focus()"
      >{{ hint || '' }}</span>
    </div>
    <calendar
      v-if="isOpenCalendar && birth"
      v-model="birthValue"
      is-dark
      color="red"
      :locale="'en'"
    ></calendar>
    <Arrow
      v-if="birth"
      class="input-arrow"
    ></Arrow>
    <Eye
      v-if="type === 'password'"
      :class="{ 'input-eye': true, 'input-eye-active': typeInput === 'text' }"
      @click="visiblePassword"
    ></Eye>
  </div>
</template>

<script>

  import DatePicker from 'v-calendar/lib/components/date-picker.umd'

  import Arrow from '@/assets/img/arrow_test.svg'
  import Eye from '../../assets/img/eye.svg'

  import './Input.scss'

  export default {
    components: { Eye, calendar: DatePicker, Arrow },
    props: ['name', 'value',
            'type', 'placeholder',
            'marginBottom', 'required',
            'hint', 'birth', 'isOpenCalendar',
            'alwaysActive',
            'readonly',
    ],
    data() {
      return {
        typeInput: this.type,
        active: false,
        birthValue: '',
      }
    },
    watch: {
      birthValue() {
        let date = this.birthValue.getDate()
        let month = this.birthValue.getMonth() + 1
        const year = this.birthValue.getFullYear()
        if (date < 10) date = `0${date}`
        if (month < 10) month = `0${month}`
        this.update(`${month}/${date}/${year}`)
        this.$emit('closeCalendar')
        if (this.birthValue.toString().length) {
          this.active = true
        }
      },
      value() {
        if (this.value) {
          this.active = true
        }
      },
    },
    methods: {
      update(value) {
        this.$emit('inputChange', { value, name: this.name })
      },
      visiblePassword() {
        this.typeInput = this.typeInput === 'password' ? 'text' : 'password'
      },
      focus(event) {
        if (!this.readonly) event.target.removeAttribute('readonly')
        this.$emit('focus')
        this.active = true
      },
      blur(event) {
        this.$emit('blur', event.target)
        if (!this.value.length > 0 && !this.birth) {
          this.active = false
        }
      },
    },
  }
</script>
