<template>
  <div
    v-if="client"
    class="usernameage"
  >
    <div class="usernameage-avatar">
      <Female v-if="client.client.gender === 'Female'"></Female>
      <Male v-if="client.client.gender === 'Male'"></Male>
    </div>
    <div class="usernameage-desc">
      <h3>{{ client.firstName }} {{ client.lastName }} </h3>
      <p>ID: {{ client.username }}</p>
      <p>Age {{ ageCount }}</p>
    </div>
  </div>
</template>

<script>
  import { date } from '@/utilites/functions'

  import Female from '../../assets/img/female.svg'
  import Male from '../../assets/img/male.svg'

  export default {
    components: {

      Male,
      Female,
    },
    props: ['client'],
    computed: {
      ageCount() {
        if (this.client == null) return null
        return date(this.client.client.dateOfBirth, 'b')
      },
    },
  }
</script>

<style lang="scss" scoped>
.usernameage {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1.15rem;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  &-avatar {
    margin-right: 1rem;
  }

  &-desc {
    display: flex;
    flex-direction: column;
    justify-self: space-between;

    h3 {
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }

    p {
      margin-bottom: 0.2rem;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1rem;
    }
  }
}
</style>
