<template>
  <div
    id="app"
    class="wrapper"
  >
    <router-view
      :user="user"
      :token="token"
      :user-id="userId"
      @setUserData="setUserData"
      @logoutController="logoutController"
      @pushController="pushController"
    ></router-view>
    <Start :open="start"></Start>
    <Logout
      :open="logout"
      :token="token"
      @logoutController="logoutController"
      @removeUserData="removeUserData"
      @pushController="pushController"
      @closeClick="logout = false"
    ></Logout>
    <Push
      :open="push"
      :text="pushText"
    ></Push>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  /* eslint-disable no-sequences */
  /* eslint-disable func-names */
  import { logout } from '@/utilites/api'
  import Start from './components/Start/Start'
  import Logout from './components/Logout/Logout'
  import Push from './components/Push/Push'

  // eslint-disable-next-line no-extend-native
  Array.prototype.chunk = function (n) {
    if (!this.length) {
      return []
    }
    return [this.slice(0, n)].concat(this.slice(n).chunk(n))
  }

  export default {
    name: 'App',
    components: { Start, Logout, Push },
    data() {
      return {
        start: true,
        logout: false,
        push: false,
        pushText: '',
        timer: null,
      }
    },
    computed: {
      ...mapState(['user', 'token']),
      userId() {
        if (this.user && this.user.caregiver) return this.user.caregiver.id
        return null
      },
    },
    mounted() {
      window.onload = this.resetTimer
      window.onmousemove = this.resetTimer
      window.onmousedown = this.resetTimer
      window.ontouchstart = this.resetTimer
      window.onclick = this.resetTimer
      window.onkeypress = this.resetTimer
      window.addEventListener('scroll', this.resetTimer, true)

      if (this.user && this.token) {
        this.start = false
      }

      if (!this.user) {
        this.start = false
        if (!this.user && this.$route.path !== '/login') {
          this.$router.replace('/login')
        }
      } else if (this.user && this.$route.path === '/login') {
        this.$router.replace('/dashboard')
      }

      this.$root.$on('root-push-controller', this.pushController)
    },
    methods: {
      ...mapMutations(['auth']),
      setUserData({ user, token }) {
        this.auth({ user, token })
      },
      removeUserData() {
        this.auth({ user: null, token: null })
      },
      logoutController() {
        this.logout = !this.logout
      },
      pushController(text) {
        this.pushText = text
        this.push = true

        setTimeout(() => {
          this.push = false
        }, 1000)
      },
      logoutUser() {
        if (this.user) {
          logout(this.token).then((res) => {
            if (res.data?.logout) {
              this.$router.push({
                name: 'login',
                params: { message: 'The user successfully logged out' },
              })
              this.removeUserData()
            } else {
              this.pushController(res.errors?.pop().message)
            }
          })
        }
        this.resetTimer()
      },
      resetTimer() {
        clearTimeout(this.timer)
        this.timer = setTimeout(this.logoutUser, 1000 * 60 * 15)
      },
    },
  }
</script>
