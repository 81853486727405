import Vue from 'vue'
import App from './App.vue'
import router from './utilites/router'
import store from './utilites/store'

Vue.config.productionTip = false

const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
})

vue.$mount('#app')
window.onbeforeprint = (event) => {
  store.commit('setPrint', true)
}
window.onafterprint = (event) => {
  store.commit('setPrint', false)
}

export default vue
