<template>
  <div class="add-client-wrapper">
    <div class="add-client">
      <h2 class="add-client-header">
        <Back @click="$router.back()"></Back> Add new client
      </h2>
      <div class="add-client-body">
        <div
          v-if="errorMessage"
          :class="['add-client-body-error', { 'add-client-body-error-active': error }]"
          v-html="errorMessage"
        >
        </div>
        <div class="add-client-body-desc">
          <span>*</span> - mandatory data entries
        </div>
        <form
          class="add-client-body-inputs"
          @submit.prevent="send"
        >
          <div class="inputs-wrapper">
            <Input
              :value="inputs.first_name"
              :name="'first_name'"
              :type="'text'"
              :placeholder="'First name'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.last_name"
              :name="'last_name'"
              :type="'text'"
              :placeholder="'Last name'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="birth"
              :name="'birth'"
              :type="'text'"
              :placeholder="'Date of birth'"
              :margin-bottom="37"
              :required="true"
              :birth="true"
              :is-open-calendar="isOpenCalendar"
              @inputChange="inputChange"
              @focus="focusBirth"
              @blur="blurBirth"
              @closeCalendar="closeCalendar"
            ></Input>
            <div class="add-client-body-inputs-gender">
              <div
                :class="['gender-item', { 'gender-item-active': inputs.gender === 'male' }]"
                @click="inputs = { ...inputs, gender: 'male' }"
              >
                <div class="circle">
                  <span></span>
                </div>
                Male
              </div>
              <div
                :class="['gender-item', { 'gender-item-active': inputs.gender === 'female' }]"
                @click="inputs = { ...inputs, gender: 'female' }"
              >
                <div class="circle">
                  <span></span>
                </div>
                Female
              </div>
            </div>
            <Input
              :value="inputs.email"
              :name="'email'"
              :type="'text'"
              :placeholder="'Contact email'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.phone"
              :name="'phone'"
              :type="'text'"
              :placeholder="'Contact phone'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.address"
              :name="'address'"
              :type="'text'"
              :placeholder="'Contact address'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.notes"
              :name="'notes'"
              :type="'text'"
              :placeholder="'Health notes'"
              :margin-bottom="37"
              :required="false"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.username"
              :name="'username'"
              :type="'text'"
              :placeholder="'Username'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.password"
              :name="'password'"
              :type="'password'"
              :placeholder="'Password'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.repeat"
              :name="'repeat'"
              :type="'password'"
              :placeholder="'Re-enter password'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
          </div>
          <div class="nav-wrapper">
            <Button
              :title="'Create'"
              @click="send"
            ></Button>
            <Button
              :title="'Cancel'"
              :margin="0"
              :mode="'cancel'"
              @click="$router.back()"
            ></Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

  import { validateEmail } from '@/utilites/functions'

  import { createUser } from '@/utilites/api'

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'

  import Back from '@/assets/img/arrow_back.svg'
  import './AddClient.scss'

  export default {
    components: { Input, Button, Back },
    props: ['token'],
    data() {
      return {
        inputs: {
          notes: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          address: '',
          username: '',
          password: '',
          repeat: '',
          gender: 'male',
        },
        error: false,
        errorMessage: '',
        isOpenCalendar: false,
        birth: '',
      }
    },
    methods: {
      inputChange(value) {
        if (value.name === 'birth') {
          this.birth = value.value
          this.error = false
        } else {
          this.inputs[value.name] = value.value
          this.error = false
        }
      },
      inputCheck(input) {
        if (!this.inputs.first_name.trim() || !this.inputs.last_name.trim() || !this.inputs.email.trim() || !this.inputs.username.trim() || !this.inputs.password.trim() || !this.inputs.repeat.trim() || !this.birth.toString().trim()) {
          this.error = true
          this.errorMessage = 'Fill in all required fields'
          return false
        } if (!validateEmail(this.inputs.email)) {
          this.error = true
          this.errorMessage = 'Please enter a valid email address'
          return false
        } if (this.inputs.password.length < 6) {
          this.error = true
          this.errorMessage = 'The password must contain at least 6 characters'
          return false
        } if (this.inputs.password !== this.inputs.repeat) {
          this.error = true
          this.errorMessage = 'Passwords don\'t match'
          return false
        }
        return true
      },
      send() {
        if (this.inputCheck()) {
          createUser(this.token, { ...this.inputs, birth: this.birth })
            .then((res) => {
              if (res.errors) {
                this.errorMessage = res.errors.pop().message
                this.error = true
              } else {
                this.$router.push('/dashboard')
              }
            })
        }
      },
      focusBirth() {
        this.error = false
        this.isOpenCalendar = true
      },
      closeCalendar() {
        this.isOpenCalendar = false
      },
      blurBirth() {
        this.inputCheck()
      },
    },
  }
</script>
