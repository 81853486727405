/* eslint-disable max-len */
/* eslint-disable no-return-await */
const axios = require('axios').default
const vue = require('../main')

// warning, logout after any `access denied` error
axios.interceptors.response.use(
  (response) => {
    if (response
      && response.data
      && response.data.errors
      && response.data.errors[0]
      && response.data.errors[0].message === 'access denied'
    ) {
      vue.default.$store.commit('auth', { user: null, token: null })
      vue.default.$router.replace('/')
    }
    return response
  },
)

const API = process.env.VUE_APP_API_ENDPOINT
export async function auth(login, password) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation login($input:Login!) { login(input:$input){id user{id userType firstName lastName namePrefix nameSuffix phone email username roles address status language measuringSystem lastAccessed lastResetPassword isPasswordExpired createdAt caregiver {id businessName shareCode }client{id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide{id caregiver{id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }preferredBreathRate audioGuide audioType audioFile disclaimerAccepted lastTested }enabledProcedures{id procedureId name isEnabled createdAt }}expiredAt }}","variables":{"input":{"username":"${login}","email":null,"password":"${password}"}}}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.data)
}

export async function logout(token) {
  return await axios({
    method: 'post',
    url: API,
    data: '{"operationName":null,"query":"mutation logout{logout}","variables":{}}',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function reset(email) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation requestResetPassword($input:RequestResetPassword!) { requestResetPassword(input:$input)}","variables":{"input":{"email":"${email}","username":null}}}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.data)
}

export async function register(data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation register($input:NewUser!) { register(input:$input){id userType firstName lastName namePrefix nameSuffix phone email username roles address status language measuringSystem lastAccessed lastResetPassword isPasswordExpired createdAt caregiver {id businessName shareCode }client{id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide{id caregiver{id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }preferredBreathRate audioGuide audioType audioFile disclaimerAccepted lastTested }enabledProcedures{id procedureId name isEnabled createdAt }}}","variables":{"input":{"account":{"userType":"Caregiver","firstName":"${data.first_name}","lastName":"${data.last_name}","namePrefix":"${data.eg1}","nameSuffix":"${data.eg2}","phone":"${data.phone}","email":"${data.email}","username":"${data.username}","password":"${data.password}","address":"${data.address}","language":"en-US","measuringSystem":"Standard"},"client":null,"caregiver":{"businessName":"${data.business}"}}}}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.data)
}

export async function getClients(data, token) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"query caregiverClients($caregiverId:ID!,$filter:FindFilter) { caregiverClients(caregiverId: $caregiverId,filter: $filter){total nodes {id userType firstName lastName namePrefix nameSuffix phone email username roles address status language measuringSystem lastAccessed lastResetPassword isPasswordExpired createdAt caregiver {id businessName shareCode }client {id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide {id caregiver {id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }preferredBreathRate audioGuide audioType audioFile disclaimerAccepted lastTested }enabledProcedures {id procedureId name isEnabled createdAt }}}}","variables":{"caregiverId":${data.id},"filter":{"search":null,"isPredefined":null,"limit":100,"offset":0}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function createUser(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation createCaregiverClient($input:NewCaregiverClient!) { createCaregiverClient(input: $input){id userType firstName lastName namePrefix nameSuffix phone email username roles address status language measuringSystem lastAccessed lastResetPassword isPasswordExpired createdAt caregiver {id businessName shareCode }client{id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide{id caregiver {id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }preferredBreathRate audioGuide audioType audioFile disclaimerAccepted lastTested }enabledProcedures{id procedureId name isEnabled createdAt }}}","variables":{"input":{"firstName":"${data.first_name}","lastName":"${data.last_name}","namePrefix":"","nameSuffix":"","phone":"${data.phone}","email":"${data.email}","username":"${data.username}","password":"${data.password}","gender":"${data.gender === 'male' ? 'Male' : 'Female'}","address":"${data.address}", "language":"en-US","measuringSystem":"Standard","audioGuide":false,"dateOfBirth":"${data.birth}","healthNotes":"${data.notes}","preferredBreathRate":"6","audioType":"AudioMIDI","audioFile":"","difficulty":"Easy"}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function updateAccount(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation updateUser($input:UpdateUser!) { updateUser(input: $input){id userType firstName lastName namePrefix nameSuffix phone email username  roles address status language measuringSystem lastAccessed lastResetPassword createdAt caregiver {id businessName shareCode }client {id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide {id caregiver {id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime createdAt }}enabledProcedures {id procedureId name isEnabled createdAt }}}","variables":{"input":{"id":${data.id},"firstName":"${data.first_name}","lastName":"${data.last_name}","namePrefix":"${data.eg1}","nameSuffix":"${data.eg2}","phone":"${data.phone}", "email":"${data.email}","username":"${data.username}","address":"${data.address}","status":null,"roles":null,"isPasswordExpired":null,"language":null,"measuringSystem":null,"client":null,"caregiver":{"businessName":"${data.business}"}}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function editUser(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation updateUser($input:UpdateUser!){ updateUser(input: $input){id userType firstName lastName namePrefix nameSuffix phone email username roles address status language measuringSystem lastAccessed lastResetPassword isPasswordExpired createdAt caregiver {id businessName shareCode }client{id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide{id caregiver{id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }preferredBreathRate audioGuide audioType audioFile disclaimerAccepted lastTested }enabledProcedures{id procedureId name isEnabled createdAt }}}","variables":{"input":{"id":${data.id},"firstName":"${data.firstName}","lastName":"${data.lastName}","namePrefix":null,"nameSuffix":null,"phone":"${data.phone}","email":"${data.email}","username":"${data.username}","address":"${data.address}","status":null,"roles":null,"newPassword":null,"isPasswordExpired":null,"language":"en-US","measuringSystem": null,"client":{"gender":"${data.gender === 'male' ? 'Male' : 'Female'}","dateOfBirth":"${data.birth}","heathNotes":"${data.notes}","preferredBreathRate":null,"audioType":null,"audioFile":null,"guideId":null,"disclaimerAccepted":null,"difficulty":null},"caregiver":null}}}`.replace(/\r?\n/g, ''),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function inviteUser(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation inviteClient($email:String!) {inviteClient(email: $email)}","variables":{"email":"${data.email}"}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function archiveUser(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation detachingClient($clientId:ID!) { detachingClient(clientId: $clientId)}","variables":{"clientId":${data.clientId}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function getUser(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"query clientData($clientId:ID!,$filter:ClientDataFilter) {clientData(clientId: $clientId,filter: $filter){total nodes {id procedure{id name createdAt }user{id userType firstName lastName namePrefix nameSuffix phone email username roles address status language measuringSystem lastAccessed lastResetPassword isPasswordExpired createdAt caregiver {id businessName shareCode }client{id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide{id caregiver{id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }preferredBreathRate audioGuide audioType audioFile disclaimerAccepted lastTested }enabledProcedures {id procedureId name isEnabled createdAt }}procedureComment data rawData1 rawData2 createdAt testedDate }}}","variables":{"clientId":${data.id},"filter":null}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function sendComment(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation updateProcedureData($input:UpdateData!) {updateProcedureData(input: $input){id procedure {id name createdAt }user{id userType firstName lastName namePrefix nameSuffix phone email username roles address status language measuringSystem lastAccessed lastResetPassword isPasswordExpired createdAt caregiver {id businessName shareCode }client{id caregiverRegistrationNumber gender dateOfBirth heathNotes difficulty guide{id caregiver{id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }preferredBreathRate audioGuide audioType audioFile disclaimerAccepted lastTested }enabledProcedures{id procedureId name isEnabled createdAt }}procedureComment data rawData1 rawData2 createdAt testedDate }}","variables":{"input":{"id": ${data.id},"procedureComment":"${data.comment}"}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function deleteTest(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation deleteData($id:ID!) { deleteData(id: $id)}","variables":{"id": ${data.id}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function getCaregiverGuides(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"query caregiverGuides($caregiverId:ID!,$filter:FindFilter) { caregiverGuides(caregiverId: $caregiverId,filter: $filter){total nodes {id caregiver {id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }}}","variables":{"caregiverId":${data.id},"filter":null}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function getCaregiverGuide(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"query caregiverGuides($caregiverId:ID!,$filter:FindFilter) { caregiverGuides(caregiverId: $caregiverId,filter: $filter){total nodes {id caregiver {id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }}}","variables":{"caregiverId":${data.caregiverId},"filter":null}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function createCaregiverGuide(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation createGuide($input:NewGuide!) { createGuide(input: $input){id caregiver {id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }}","variables":{"input":{"name":"${data.name}","inhaleTime":${data.inhaleTime},"holdTime":${data.holdTime},"exhaleTime":${data.exhaleTime},"pauseTime":${data.pauseTime}}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function updateCaregiverGuide(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation updateGuide($input:UpdateGuide!) { updateGuide(input: $input){id caregiver {id businessName shareCode }name inhaleTime holdTime exhaleTime pauseTime isPredefined createdAt }}","variables":{"input":{"id":${data.id},"name":"${data.name}","inhaleTime":${data.inhaleTime},"holdTime":${data.holdTime},"exhaleTime":${data.exhaleTime},"pauseTime":${data.pauseTime}}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}

export async function deleteCaregiverGuide(token, data) {
  return await axios({
    method: 'post',
    url: API,
    data: `{"operationName":null,"query":"mutation deleteGuide($id:ID!) { deleteGuide(id: $id)}","variables":{"id":${data.id}}}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.data)
}
