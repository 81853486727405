<template>
  <div
    :class="['user-wrapper',{ print }]"
    @click.capture="hideMobileMenu"
  >
    <div :class="['user',{ print,pdf }]">
      <MobileHead
        v-if="!print"
        searching-thing=""
        @open="mobileOpen = true"
        @search="mobileHeadSearch"
      ></MobileHead>

      <UserHeader
        :is-open-mobile="mobileOpen"
        :tab="tab"
        :client="client"
        @changeTab="changeTab"
      ></UserHeader>

      <UserBase
        v-if="client"
        :user="client"
        :test="tests[tab].find(item => item.active)"
        :diff="diff"
        :tab="tab"
        @pushController="pushController"
        @commentController="commentController"
        @deleteController="deleteController"
        @changeDiff="changeDiff"
        @changeShow="changeShow"
      ></UserBase>

      <Comment
        :open="isOpenComments"
        :token="token"
        :test="tests[tab].find(item => item.active)"
        @commentController="commentController"
        @pushController="pushController"
      ></Comment>
      <Delete
        :open="isOpenDelete"
        :token="token"
        :test="tests[tab].find(item => item.active)"
        @deleteController="deleteController"
        @pushController="pushController"
        @deleteTest="deleteTest"
      ></Delete>
      <div
        v-if="!isHistory && tests[tab].find(item => item.active)"
        class="user-test"
      >
        <Arrow @click="changeTest('next')"></Arrow>

        <span
          style="width: auto; text-align: center;"
          v-html="testDate"
        ></span>

        <Arrow
          class="right"
          @click="changeTest('prev')"
        ></Arrow>
      </div>
      <div
        v-if="!isHistory && testQuality != null"
        class="user-quality"
      >
        <span>Data Quality:</span>
        <Good
          v-if="testQuality === 'good'"
          class="icon"
        >
        </Good>
        <Bad
          v-else
          class="icon"
        >
        </Bad>
      </div>
      <router-view
        v-if="tests[tab].find(item => item.active)"
        name="user"
        :user="client"
        :test="tests[tab].find(item => item.active)"
      ></router-view>

      <div
        v-if="print"
        class="user-caregiver"
      >
        <div class="clinic">
          {{ user.caregiver.businessName }}
        </div>
        <div>{{ user.namePrefix }} {{ user.firstName }} {{ user.lastName }}, {{ user.nameSuffix }}</div>
        <div>{{ user.address }}</div>
        <div>Phone: {{ user.phone }} / Email: {{ user.email }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'

  import MobileHead from '@/components/MobileHead/MobileHead'

  import UserHeader from '@/components/UserHeader/UserHeader'
  import UserBase from '@/components/UserBase/UserBase'
  import Comment from '@/components/Comment/Comment'
  import Delete from '@/components/Delete/Delete'

  import Arrow from '@/assets/img/arrow_test.svg'
  import Good from '@/assets/img/good.svg'
  import Bad from '@/assets/img/bad.svg'
  import { date } from '../../utilites/functions'
  import { getUser, getClients } from '../../utilites/api'

  import './User.scss'

  export default {
    components: {
      MobileHead, UserHeader, UserBase, Arrow, Comment, Delete, Good, Bad,
    },
    props: ['token'],
    data() {
      return {
        mobileOpen: false,
        client: null,
        tests: {
          single: [],
          prepost: [],
          cardiac: [],
          breatwork: [],
        },
        isOpenComments: false,
        isOpenDelete: false,
      }
    },
    computed: {
      ...mapState(['user', 'print', 'valueDiff', 'tabActive', 'pdf']),
      isHistory() {
        return this.$route.name === 'history'
      },
      diff: {
        get() {
          return this.valueDiff
        },
        set(v) {
          this.setValueDiff(v)
        },
      },
      tab: {
        get() {
          return this.tabActive
        },
        set(v) {
          this.setTabActive(v)
        },
      },
      userId() {
        if (this.user && this.user.caregiver) return this.user.caregiver.id
        return null
      },
      testQuality() {
        const testIndex = this.tests[this.tab].findIndex((item) => item.active)
        if (testIndex < 0) {
          return null
        }
        let seed = this.tests[this.tab][testIndex]
        if (seed.pre != null) {
          seed = seed.pre
        }

        if (seed.data && seed.data.Arrhythmias != null) {
          if (seed.data.Arrhythmias < 10) return 'good'
          return 'bad'
        }
        return null
      },
      testDate() {
        const testIndex = this.tests[this.tab].findIndex((item) => item.active)
        if (testIndex < 0) {
          return ''
        }
        let seed = date(this.tests[this.tab][testIndex].date, 'td')

        if (this.tab === 'prepost') {
          seed = date(this.tests[this.tab][testIndex].pre.date, 'td')
          if (this.tests[this.tab][testIndex].post) {
            seed = seed.replace(' ', '&nbsp;')
            seed = `${seed}&nbsp; - &nbsp;${date(this.tests[this.tab][testIndex].post.date, 'td')}`
          } else {
            seed = `${seed}&nbsp;&nbsp;-&nbsp;&nbsp;N/A`
          }
        }

        return seed
      },
    },
    mounted() {
      getClients({ id: this.userId, search: null }, this.token)
        .then((res) => {
          if (res.data) {
            const clients = res.data.caregiverClients.nodes
            const client = clients.find((cData) => cData.client.id === this.$route.params.id)
            if (client) this.client = client
            this.clientData()
          } else {
            this.$emit('pushController', res.errors[0].message)
          }
        })

      const availableLinks = [
        'single',
        'prepost',
        'cardiac',
        'breatwork',
        'history',
      ]
      const tailLink = window.location.href.split('/').pop()
      if (availableLinks.includes(tailLink)) {
        this.tab = tailLink
      }
    },
    methods: {
      ...mapMutations(['setValueDiff', 'setTests', 'setTabActive']),
      hideMobileMenu(event) {
        if (!event.target.classList.contains('user-header') && !event.target.classList.contains('user-header-btn-archive')) {
          this.mobileOpen = false
        }
      },
      mobileHeadSearch(val) {
      },
      clientData() {
        getUser(this.token, { id: this.$route.params.id })
          .then((res) => {
            if (res.errors) {
              this.pushController(res.errors.pop().message)
              if (this.$route.path != '/dashboard') this.$router.push('/dashboard')
            } else if (res.data.clientData.nodes[0]) {
              this.readTest(res.data.clientData.nodes)
              if (this.$route.path === `/user/${this.$route.params.id}`) this.$router.push(`/user/${this.$route.params.id}/single`)
            }
          })
      },
      pushController(message) {
        this.$emit('pushController', message)
      },
      commentController() {
        this.isOpenComments = !this.isOpenComments
      },
      deleteController() {
        this.isOpenDelete = !this.isOpenDelete
      },
      readTest(tests) {
        tests = tests.map((item) => ({ ...item, show: true, data: JSON.parse(JSON.parse(item.data)) }))
          .filter((item) => !(item.procedure.name === 'Biological age'))

        const bodys = tests.filter((body) => body.procedure.name === 'Body measurements').sort((a, b) => a.createdAt - b.createdAt)
        const compose = tests.filter((body) => body.procedure.name === 'Body composition').sort((a, b) => a.createdAt - b.createdAt)

        const glucose = tests.filter((body) => body.procedure.name === 'Blood glucose').sort((a, b) => a.createdAt - b.createdAt)
        const pressure = tests.filter((body) => body.procedure.name === 'Blood pressure').sort((a, b) => a.createdAt - b.createdAt)

        const single = tests.filter((item) => item.procedure.name === 'Health test' && !item.data.Category).map(
          (item, index, arr) => {
            const date = item.testedDate ? new Date(item.testedDate).getTime() : new Date(item.createdAt).getTime()
            return {
              ...item,
              date,
              body: bodys.reverse().find((i) => i.createdAt < item.createdAt) || bodys.find((i) => i.createdAt > item.createdAt),
              compose: compose.reverse().find((i) => i.createdAt < item.createdAt) || compose.find((i) => i.createdAt > item.createdAt),
              glucose: glucose.reverse().find((i) => i.createdAt < item.createdAt) || glucose.find((i) => i.createdAt > item.createdAt),
              pressure: pressure.reverse().find((i) => i.createdAt < item.createdAt) || pressure.find((i) => i.createdAt > item.createdAt),
              active: index === arr.length - 1,
              index,
              type: 'single',
            }
          },
        )

        const pre = tests.filter((item) => item.procedure.name === 'Health test' && item.data.Category === 1).map((item, index) => {
          const date = item.testedDate ? new Date(item.testedDate).getTime() : new Date(item.createdAt).getTime()
          return {
            ...item,
            date,
            body: bodys.reverse().find((i) => i.createdAt < item.createdAt) || bodys.find((i) => i.createdAt > item.createdAt),
            compose: compose.reverse().find((i) => i.createdAt < item.createdAt) || compose.find((i) => i.createdAt > item.createdAt),
            glucose: glucose.reverse().find((i) => i.createdAt < item.createdAt) || glucose.find((i) => i.createdAt > item.createdAt),
            pressure: pressure.reverse().find((i) => i.createdAt < item.createdAt) || pressure.find((i) => i.createdAt > item.createdAt),
          }
        })
        const post = tests.filter((item) => item.procedure.name === 'Health test' && item.data.Category === 2).map((item, index) => {
          const date = item.testedDate ? new Date(item.testedDate).getTime() : new Date(item.createdAt).getTime()
          return {
            ...item,
            date,
            body: bodys.reverse().find((i) => i.createdAt < item.createdAt) || bodys.find((i) => i.createdAt > item.createdAt),
            compose: compose.reverse().find((i) => i.createdAt < item.createdAt) || compose.find((i) => i.createdAt > item.createdAt),
            glucose: glucose.reverse().find((i) => i.createdAt < item.createdAt) || glucose.find((i) => i.createdAt > item.createdAt),
            pressure: pressure.reverse().find((i) => i.createdAt < item.createdAt) || pressure.find((i) => i.createdAt > item.createdAt),
          }
        })

        const prepost = pre.map((item, index) => ({
          pre: item,
          post: post[index],
          date: item.date,
          index,
          id: item.id,
          active: index === pre.length - 1,
          type: 'prepost',
        }))

        const cardiac = tests.filter((item) => item.procedure.name === 'Breathwork' && item.data.Category === 4).map((item, index, arr) => {
          const date = item.testedDate ? new Date(item.testedDate).getTime() : new Date(item.createdAt).getTime()
          const raw = JSON.parse(JSON.parse(item.rawData2)).filter((item) => item > 0)
          const rawAll = raw.length / 100
          const poor = raw.filter((item) => (item > 0 && item < 30)).length / rawAll
          const fair = raw.filter((item) => (item >= 30 && item < 60)).length / rawAll
          const good = raw.filter((item) => (item >= 60)).length / rawAll
          return {
            ...item,
            date,
            active: index === arr.length - 1,
            index,
            resonance: {
              poor,
              fair,
              good,
            },
            type: 'cardiac',
            body: bodys.reverse().find((i) => i.createdAt < item.createdAt) || bodys.find((i) => i.createdAt > item.createdAt),
            compose: compose.reverse().find((i) => i.createdAt < item.createdAt) || compose.find((i) => i.createdAt > item.createdAt),
            glucose: glucose.reverse().find((i) => i.createdAt < item.createdAt) || glucose.find((i) => i.createdAt > item.createdAt),
            pressure: pressure.reverse().find((i) => i.createdAt < item.createdAt) || pressure.find((i) => i.createdAt > item.createdAt),
          }
        })
        const breatwork = tests.filter((item) => item.procedure.name === 'Breathwork' && item.data.Category === 3).map((item, index, arr) => {
          const date = item.testedDate ? new Date(item.testedDate).getTime() : new Date(item.createdAt).getTime()
          const raw = JSON.parse(JSON.parse(item.rawData2)).filter((item) => item > 0)
          const rawAll = raw.length / 100
          const poor = raw.filter((item) => (item > 0 && item < 30)).length / rawAll
          const fair = raw.filter((item) => (item >= 30 && item < 60)).length / rawAll
          const good = raw.filter((item) => (item >= 60)).length / rawAll
          return {
            ...item,
            date,
            active: index === arr.length - 1,
            index,
            resonance: {
              poor,
              fair,
              good,
            },
            body: bodys.reverse().find((i) => i.createdAt < item.createdAt) || bodys.find((i) => i.createdAt > item.createdAt),
            compose: compose.reverse().find((i) => i.createdAt < item.createdAt) || compose.find((i) => i.createdAt > item.createdAt),
            glucose: glucose.reverse().find((i) => i.createdAt < item.createdAt) || glucose.find((i) => i.createdAt > item.createdAt),
            pressure: pressure.reverse().find((i) => i.createdAt < item.createdAt) || pressure.find((i) => i.createdAt > item.createdAt),
            type: 'breatwork',
          }
        })

        const prepostRev = []
        prepost.forEach((item) => {
          prepostRev.unshift(item)
        })

        const outTests = {
          single: single.reverse(),
          prepost: prepost.reverse(),
          cardiac: cardiac.reverse(),
          breatwork: breatwork.reverse(),
        }

        this.tests = outTests
        this.setTests(JSON.parse(JSON.stringify(outTests)))
      },
      deleteTest(id) {
        this.tests[this.tab] = this.tests[this.tab].filter((item) => !(item.id === id)).map((item, index) => ({ ...item, active: index === 0, index }))
      },
      changeTab(link) {
        if (link !== 'history') {
          this.tab = link
          this.$router.push(link)
        } else {
          this.$router.push(link)
        }
        this.setValueDiff(false)
      },
      changeTest(route) {
        const indexActive = this.tests[this.tab].findIndex((item) => item.active)

        if (route === 'next') {
          if (this.tests[this.tab][indexActive + 1]) {
            this.tests = {
              ...this.tests,
              [this.tab]: this.tests[this.tab].map((item, index) => (indexActive + 1 === index ? { ...item, active: true } : { ...item, active: false })),
            }
          }
        } else if (route === 'prev') {
          if (this.tests[this.tab][indexActive - 1]) {
            this.tests = {
              ...this.tests,
              [this.tab]: this.tests[this.tab].map((item, index) => (indexActive - 1 === index ? { ...item, active: true } : { ...item, active: false })),
            }
          }
        }
      },
      changeDiff() {
        this.diff = !this.diff
      },
      changeShow(id) {
        this.tests[this.tab] = this.tests[this.tab].map((item) => (item.id === id ? { ...item, show: !item.show } : item))
      },
    },
  }
</script>
