<template>
  <div class="btn-group">
    <li
      v-if="value != null"
      class="dropdown-toggle"
      @click="toggleMenu"
    >
      {{ value.label }}
      <span
        v-if="caret"
        class="caret"
      ></span>
    </li>

    <li
      v-if="value == null"
      class="dropdown-toggle dropdown-toggle-placeholder"
      @click="toggleMenu"
    >
      {{ placeholder }}
      <span
        v-if="caret"
        class="caret"
      ></span>
    </li>

    <ul
      v-if="showMenu"
      :class="['dropdown-menu',{ 'dropdown-menu-right': right }]"
    >
      <li
        v-for="(option, idx) in options"
        :key="idx"
      >
        <a
          href="javascript:void(0)"
          @click="updateOption(option)"
        >
          {{ option.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      options: {
        type: [Array, Object],
        default: () => [],
      },
      value: {
        type: Object,
        default: () => ({}),
      },
      placeholder: {
        type: String,
        default: '',
      },
      closeOnOutsideClick: {
        type: Boolean,
        default: true,
      },
      caret: {
        type: Boolean,
        default: false,
      },
      right: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showMenu: false,
      }
    },

    mounted() {
      if (this.closeOnOutsideClick) {
        document.addEventListener('click', this.clickHandler)
      }
    },

    beforeDestroy() {
      document.removeEventListener('click', this.clickHandler)
    },

    methods: {
      updateOption(option) {
        this.showMenu = false
        this.$emit('input', option)
      },

      toggleMenu() {
        this.showMenu = !this.showMenu
      },

      clickHandler(event) {
        const { target } = event
        const { $el } = this

        if (!$el.contains(target)) {
          this.showMenu = false
        }
      },
    },
  }
</script>

<style>

.btn-group {
  height: auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.dropdown-menu > li > a {
  padding: 10px 30px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
}

.btn-group a:hover {
  text-decoration: none;
}

.dropdown-toggle {
  color: #fff;
  padding: 10px 10px 10px 10px;
  text-transform: none;
  border: 0;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-toggle:hover {
  cursor: pointer;
}

.dropdown-menu {
  overflow-y: scroll;
  max-height: 45vh;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #2d2929;
  border: 1px solid #2d2929;
  border-radius: 4px;
  background-clip: padding-box;
}

.dropdown-menu-right {
  left: unset;
  right: 0;
}

li {
  list-style: none;
}

.dropdown-menu > li > a:hover {
  opacity: 0.5;
}

.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
}

@media (max-width: 1024px) {
  .dropdown-menu > li > a {
    padding: 4px 10px;
    font-size: 12px;
  }

  .dropdown-toggle {
    font-size: 12px;
  }
}

.caret {
  width: 0;
  position: absolute;
  top: 19px;
  height: 0;
  margin-left: -24px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  right: 10px;
}
</style>
