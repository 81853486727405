<template>
  <div
    v-if="isOpenArchive"
    :class="['archive', { 'archive-active': isOpenArchive }]"
    @click="close($event)"
  >
    <div class="archive-content">
      <div class="archive-content-title">
        Archive client
      </div>

      <div
        v-if="client"
        class="archive-content-header"
      >
        <div class="archive-content-header-avatar">
          <Female v-if="client.client.gender === 'Female'"></Female>
          <Male v-if="client.client.gender === 'Male'"></Male>
        </div>
        <div class="archive-content-header-desc">
          <h3>{{ client.firstName }} {{ client.lastName }} </h3>
          <p>ID: {{ client.username }}</p>
          <p>Age {{ ageCount }}</p>
        </div>
      </div>

      <p class="archive-info archive-info-show">
        You are about to archive this client record.
        All date associated with this client will become inaccessible.
        Click ARCHIVE IT to commit client record archivation.
      </p>

      <div class="archive-content-nav">
        <Button
          title="Archive it"
          :margin-bottom="15"
          @click="send"
        ></Button>
        <Button
          :title="'Cancel'"
          :margin="0"
          :mode="'cancel'"
          @click="$emit('close')"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { archiveUser } from '@/utilites/api'
  import { date } from '@/utilites/functions'
  import Button from '@/components/Button/Button'
  import Female from '../../assets/img/female.svg'
  import Male from '../../assets/img/male.svg'

  import './ArchiveClient.scss'

  export default {
    components: {
      Button,
      Male,
      Female,
    },
    props: ['isOpenArchive', 'client'],
    computed: {
      ...mapState(['token']),
      ageCount() {
        if (this.client == null) return null
        return date(this.client.client.dateOfBirth, 'b')
      },
    },
    methods: {
      send() {
        if (!this.client.client.id) {
          this.$root.$emit('root-push-controller', 'undefined client id')
          return
        }
        archiveUser(this.token, { clientId: this.client.client.id })
          .then((res) => {
            if (!res.data) {
              this.$root.$emit('root-push-controller', res.errors.pop().message)
            } else {
              this.$emit('pushArchive')
              this.$emit('close')
            }
          })
      },
      close(event) {
        if (event.target.classList.contains('archive-active') || event.target.classList.contains('archive')) {
          this.$emit('close')
        }
      },
    },
  }
</script>
