<template>
  <button
    :class="['button', { 'button-cancel': mode === 'cancel' }]"
    :style="{ width: width + 'px', marginRight: margin + 'px', marginBottom: marginBottom + 'px', height: height + 'px' }"
    @click.prevent.stop="$emit('click')"
  >
    {{ title }}
  </button>
</template>

<script>

  import './Button.scss'

  export default {
    props: ['title', 'width', 'mode', 'margin', 'marginBottom', 'height'],
    data: () => ({

    }),
  }
</script>
