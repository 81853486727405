<template>
  <div
    :class="['invite', { 'invite-active': isOpenInvite }]"
    @click="close($event)"
  >
    <div class="invite-content">
      <div class="invite-content-header">
        Invite client
      </div>
      <p class="invite-content-title">
        Enter your client email adress and send an invitation to share health date.
      </p>
      <Input
        :value="email"
        :name="'email'"
        :type="'text'"
        :placeholder="'Contact email'"
        :margin-bottom="37"
        @inputChange="inputChange"
        @focus="error = false"
      ></Input>
      <div class="invite-content-nav">
        <Button
          :title="'Send'"
          :width="315"
          :margin-bottom="15"
          @click="sendInvite"
        ></Button>
        <Button
          :title="'Cancel'"
          :width="315"
          :margin="0"
          :mode="'cancel'"
          @click="$emit('closeInvite')"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>

  import { validateEmail } from '@/utilites/functions'

  import { inviteUser } from '@/utilites/api'

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'

  import './InviteClient.scss'

  export default {
    components: { Input, Button },
    props: ['isOpenInvite', 'token'],
    data() {
      return {
        email: '',
      }
    },
    watch: {
      isOpenInvite() {
        this.email = ''
      },
    },
    methods: {
      inputChange(value) {
        this.email = value.value
      },
      inputCheck(input) {
        if (!validateEmail(this.email)) {
          this.$emit('pushController', 'Please enter a valid email address')
          return false
        }
        return true
      },
      sendInvite() {
        if (this.inputCheck()) {
          inviteUser(this.token, { email: this.email })
            .then((res) => {
              if (!res.data) {
                this.$emit('pushController', res.errors.pop().message)
              } else {
                this.$emit('pushInvite')
              // this.$router.
              }
              this.$emit('closeInvite')
            })
        }
      },
      close(event) {
        if (event.target.classList.contains('invite-active') || event.target.classList.contains('invite')) {
          this.$emit('closeInvite')
        }
      },
    },
  }
</script>
