<template>
  <div class="configuration-wrapper">
    <div class="configuration">
      <h2 class="configuration-header">
        <Back @click="$router.push('dashboard')"></Back> Breath guides
      </h2>
      <div class="configuration-body">
        <div
          v-if="errorMessage"
          :class="[
            'configuration-body-error',
            { 'configuration-body-error-active': error },
          ]"
          v-html="errorMessage"
        ></div>
        <div
          v-if="loading"
          class="configuration-body-desc"
        >
          Loading <span>...</span>
        </div>
        <div
          v-if="!loading && caregiverGuides.length < 1"
          class="configuration-body-desc"
        >
          No caregiver guides found<span>...</span>
        </div>
        <form
          class="configuration-body-inputs"
          @submit.prevent="send"
        >
          <div class="inputs-wrapper">
            <div class="configuration-body-inputs-radio">
              <div
                v-for="cGuide in caregiverGuides"
                :key="cGuide.id"
                :class="['radio-item', { 'radio-item-active': cGuide === caregiverGuide }]"
                @click="() => {
                  active(cGuide)
                }"
              >
                <div class="circle">
                  <span></span>
                </div>
                {{ cGuide.name }}
              </div>
            </div>
          </div>
          <div class="nav-wrapper">
            <Button
              title="Modify preset"
              :disabled="caregiverGuide == null || caregiverGuide.isPredefined"
              @click="modify"
            ></Button>
            <Button
              title="Add preset"
              @click="create"
            ></Button>
          </div>
          <div class="nav-wrapper-delete">
            <Button
              title="Delete preset"
              :disabled="caregiverGuide == null || caregiverGuide.isPredefined"
              :mode="'cancel'"
              @click="deleteController"
            ></Button>
          </div>
        </form>
        <DeleteGuide
          :open="isOpenDelete"
          :token="token"
          :guide="caregiverGuide"
          @deleteController="deleteController"
          @pushController="pushController"
          @deleteGuide="deleteGuide"
        ></DeleteGuide>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import { validateEmail } from '@/utilites/functions'

  import { updateAccount } from '@/utilites/api'

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'
  import DeleteGuide from '@/components/DeleteGuide/DeleteGuide'
  import Back from '@/assets/img/arrow_back.svg'

  import { getCaregiverGuides } from '../../utilites/api'

  import './Configuration.scss'

  export default {
    components: {
      Input, Button, Back, DeleteGuide,
    },
    data() {
      return {
        error: true,
        errorMessage: '',
        caregiverGuides: [],
        loading: false,
        caregiverGuide: null,
        isOpenDelete: false,
      }
    },
    computed: {
      ...mapState(['user', 'token']),
    },
    mounted() {
      this.loading = true
      getCaregiverGuides(this.token, { id: this.user.caregiver.id }).then((response) => {
        if (!response.data) {
          this.errorMessage = response.errors.pop().message
          this.error = true
          this.loading = false
          return
        }

        this.caregiverGuides = response.data.caregiverGuides.nodes
      }).finally(() => {
        this.loading = false
      })
    },
    methods: {
      ...mapMutations(['updateUser']),
      inputChange(value) {
        this.inputs[value.name] = value.value
        this.error = false
      },
      active(cGuide) {
        this.caregiverGuide = cGuide
      },
      modify() {
        this.$router.push(`/configuration/${this.caregiverGuide.id}`)
      },
      create() {
        this.$router.push('/configuration/new')
      },
      pushController(message) {
        this.errorMessage = message
        this.error = true
      },
      deleteController() {
        this.isOpenDelete = !this.isOpenDelete
      },
      deleteGuide(id) {
        const index = this.caregiverGuides.findIndex((g) => g.id === id)
        if (index > -1) {
          this.caregiverGuides.splice(index, 1)
        }
      },
    },
  }
</script>
