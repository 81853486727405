<template>
  <form
    class="login"
    @submit.prevent="send"
  >
    <Logo class="logo"></Logo>
    <h1 class="login-header">
      Body Health Analyzer
    </h1>
    <p :class="['login-error', { 'login-error-active': error }]">
      {{ errorMessage }}
    </p>
    <Input
      :value="inputs.username"
      :name="'username'"
      :type="'text'"
      :placeholder="'Username'"
      :margin-bottom="30"
      @inputChange="inputChange"
      @focus="error = false"
      @blur="inputCheck"
    ></Input>
    <Input
      :value="inputs.password"
      :name="'password'"
      :type="'password'"
      :placeholder="'Password'"
      :margin-bottom="30"
      @inputChange="inputChange"
      @focus="error = false"
      @blur="inputCheck"
    ></Input>
    <Button
      :title="'Log in'"
      @click="send"
    ></Button>
    <router-link
      to="/reset"
      class="login-reset"
    >
      Forgot password
    </router-link>
    <div class="login-register">
      Don't have an account yet? <span @click="userSelect = true">Register</span>
    </div>
    <UserSelect
      :open="userSelect"
      @closeClick="userSelect = false"
    ></UserSelect>
  </form>
</template>

<script>

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'
  import UserSelect from '@/components/UserSelect/UserSelect'
  import { auth } from '../../utilites/api'

  import Logo from '../../assets/img/logo-min.svg'

  import './Login.scss'

  export default {
    components: {
      Logo, Input, Button, UserSelect,
    },
    props: ['user', 'message'],
    data: () => ({
      inputs: {
        username: '',
        password: '',
      },
      error: true,
      errorMessage: 'Please enter your username and password then click LOG IN',
      userSelect: false,
    }),
    mounted() {
      if (this.$route.params.message) {
        this.errorMessage = this.$route.params.message
        setTimeout(() => {
          this.error = false
        }, 5000)
        setTimeout(() => {
          this.errorMessage = 'Please enter your username and password then click LOG IN'
        }, 5300)
        setTimeout(() => {
          this.error = true
        }, 5500)
      }
    },
    methods: {
      inputChange(value) {
        this.inputs[value.name] = value.value
        this.error = false
      },
      inputCheck() {
        if (!this.inputs.username.trim() && !this.inputs.password.trim()) {
          this.error = true
          this.errorMessage = 'Please enter your username and password then click LOG IN'
          return false
        } if (!this.inputs.username.trim() && this.inputs.password.trim()) {
          this.error = true
          this.errorMessage = 'Please enter your username'
          return false
        } if (this.inputs.username.trim() && !this.inputs.password.trim()) {
          this.error = true
          this.errorMessage = 'Please enter your password'
          return false
        } if (this.inputs.password.trim().length < 6) {
          this.error = true
          this.errorMessage = 'The password must contain at least 6 characters'
          return false
        }
        this.error = false
        return true
      },
      send() {
        if (this.inputCheck()) {
          auth(this.inputs.username, this.inputs.password)
            .then((res) => this.checkResponse(res))
        }
      },
      checkResponse(response) {
        if (!response.data) {
          this.errorMessage = response.errors.pop().message
          this.error = true
        } else {
          this.$emit('setUserData', { user: response.data.login.user, token: response.data.login.id })

          this.$router.push('/dashboard')
        }
      },
    },
  }
</script>
