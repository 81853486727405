<template>
  <div
    :class="['user-header',{ 'user-header-close': !isOpenMobile,'user-header-start': isHistory }]"
  >
    <Back
      class="back"
      @click="back"
    ></Back>

    <div class="user-header-usernameage user-header-mobileonly">
      <UserNameAge
        v-if="!print && client"
        :client="client"
      ></UserNameAge>
    </div>

    <LogoHeader
      v-if="print"
      class="logo"
    ></LogoHeader>
    <div
      v-if="print"
      class="user-header-btn user-header-btn-active"
    >
      Body Health Analyzer Report
    </div>
    <div
      v-if="print"
      class="user-header-btn user-header-btn-active"
    >
      -
    </div>
    <div
      v-if="isHistory"
      class="user-header-history"
    >
      History
    </div>
    <div
      :class="['user-header-btn', { 'user-header-btn-active': $route.name === 'single', 'history-hide': isHistory }]"
      @click="pushTab('single')"
    >
      <List></List> Health Single
    </div>
    <div
      :class="['user-header-btn', { 'user-header-btn-active': $route.name === 'prepost', 'history-hide': isHistory }]"
      @click="pushTab('prepost')"
    >
      <List></List> Health Pre/Post
    </div>
    <div
      :class="['user-header-btn', { 'user-header-btn-active': $route.name === 'cardiac', 'history-hide': isHistory }]"
      @click="pushTab('cardiac')"
    >
      <List></List> Cardiac Resonance
    </div>
    <div
      :class="['user-header-btn', { 'user-header-btn-active': $route.name === 'breatwork', 'history-hide': isHistory }]"
      @click="pushTab('breatwork')"
    >
      <List></List> Breathwork
    </div>
    <div
      :class="['user-header-btn', { 'user-header-btn-active': $route.name === 'history', 'history-hide': isHistory }]"
      @click="pushTab('history')"
    >
      <History></History> History
    </div>
    <div
      v-if="!isHistory"
      class="user-header-btn"
      @click="$router.push({ name: 'edit-client', params: { client: client , id: client.id },
      })"
    >
      <Modify></Modify> Modify
    </div>
    <div
      v-if="!isHistory"
      :class="['user-header-btn user-header-btn-archive', { 'user-header-btn-active': tab === 6 }]"
      @click="openArchive"
    >
      <Archive></Archive> Archive
    </div>

    <ArchiveClient
      :is-open-archive="isOpenArchive"
      :client="client"
      @close="closeArchive"
      @pushArchive="pushArchive"
    ></ArchiveClient>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import { date } from '@/utilites/functions'
  import UserNameAge from '@/components/UserNameAge/UserNameAge'
  import Back from '@/assets/img/arrow_back.svg'
  import List from '@/assets/img/list.svg'
  import History from '@/assets/img/history.svg'
  import Modify from '@/assets/img/modify.svg'
  import Archive from '@/assets/img/archive.svg'
  import ArchiveClient from '../ArchiveClient/ArchiveClient.vue'
  import LogoHeader from '../../assets/img/logo-header.svg'

  import Female from '../../assets/img/female.svg'
  import Male from '../../assets/img/male.svg'

  import './UserHeader.scss'

  export default {
    components: {
      UserNameAge,
      Back,
      List,
      History,
      Modify,
      Archive,
      ArchiveClient,
      LogoHeader,
      Female,
      Male,
    },
    props: ['tab', 'client', 'isOpenMobile'],
    data() {
      return {
        isOpenArchive: false,
      }
    },
    computed: {
      ...mapState(['print']),
      isHistory() {
        return this.$route.name === 'history'
      },
      ageCount() {
        if (this.client == null) return null
        return date(this.client.client.dateOfBirth, 'b')
      },
    },
    methods: {
      ...mapMutations(['setTabActive']),
      openArchive() {
        this.isOpenArchive = true
      },
      closeArchive() {
        this.isOpenArchive = false
      },
      pushArchive() {
        this.$router.push('/dashboard')
      },
      pushTab(link) {
        if (this.$route.params && this.$route.params.id && this.$route.path.endsWith(`/${this.$route.params.id.toString()}`)) {
          return
        }
        this.$emit('changeTab', link)
      },
      back() {
        if (this.$route.path.endsWith('/history')) {
          return this.$router.back()
        }
        this.setTabActive('single')
        this.$router.replace('/dashboard')
      },
    },
  }
</script>
