<template>
  <div
    :class="['four', `height-${height}`, `width-${width}`, `marginl-${ml}px`]"
  >
    <!-- :style="{ height: height, width: width, marginLeft: ml }" -->
    <div class="four-header">
      {{ title }}
    </div>
    <div class="four-body">
      <CircleGraph
        v-for="circle in circles"
        :key="circle.title"
        :sign="valueDiff"
        :title="circle.title"
        :margin="circle.margin"
        :color="circle.color"
        :value="circle.value"
      ></CircleGraph>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CircleGraph from '@/components/CircleGraph/CircleGraph'

  import './Four.scss'

  export default {
    components: { CircleGraph },
    props: ['title', 'circles', 'height', 'width', 'ml'],
    computed: {
      ...mapState(['valueDiff']),
    },
  }
</script>
