<template>
  <div
    :class="['comment', { 'comment-active': open }]"
    @click="close($event)"
  >
    <div class="comment-content">
      <div class="comment-content-header">
        Comments
      </div>
      <Input
        :value="comment"
        :name="'comment'"
        :type="'text'"
        :placeholder="'Testing comments'"
        :margin-bottom="26"
        @inputChange="inputChange"
        @focus="error = false"
      ></Input>
      <div class="comment-content-nav">
        <Button
          :title="'Send'"
          :width="315"
          :margin-bottom="15"
          @click="sendCommentHandler"
        ></Button>
        <Button
          :title="'Cancel'"
          :width="315"
          :margin="0"
          :mode="'cancel'"
          @click="$emit('commentController')"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'
  import { sendComment } from '../../utilites/api'

  import './Comment.scss'

  export default {
    components: { Input, Button },
    props: ['open', 'token', 'test'],
    data() {
      return {
        comment: '',
      }
    },
    watch: {
      open() {
        this.comment = this.test.procedureComment
      },
    },
    methods: {
      inputChange(value) {
        this.comment = value.value
      },
      inputCheck(input) {
        if (!this.comment.length) {
          this.$emit('pushController', 'Enter comment')
          return false
        }
        return true
      },
      sendCommentHandler() {
        if (this.inputCheck()) {
          sendComment(this.token, { id: this.test.id, comment: this.comment })
            .then((res) => {
              if (!res.data) {
                this.$emit('pushController', res.errors.pop().message)
              } else {
                this.$emit('commentController')
                this.test.procedureComment = this.comment
              }
            })
        }
      },
      close(event) {
        if (event.target.classList.contains('comment-active') || event.target.classList.contains('comment')) {
          this.$emit('commentController')
        }
      },
    },
  }
</script>
