<template>
  <div
    :class="['user-select-wrapper', { 'user-select-wrapper-active': open }]"
    @click="close($event)"
  >
    <div class="user-select">
      <div class="user-select-content">
        <div class="user-select-content-header">
          Select User Type
        </div>
        <Button
          :title="'I am a professional user'"
          :margin-bottom="15"
          @click="$router.push({ name: 'register', params: { mode: 'doctor' } })"
        ></Button>
        <Button
          :title="'I am home user'"
          :mode="'cancel'"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>

  import Button from '@/components/Button/Button'

  import './UserSelect.scss'

  export default {
    components: { Button },
    props: ['open'],
    methods: {
      close(event) {
        if (event.target.classList.contains('user-select-wrapper-active') || event.target.classList.contains('user-select')) {
          this.$emit('closeClick')
        }
      },
    },
  }
</script>
