<template>
  <div class="user-data">
    <Four
      title="Cardiac Resonance Test Summary"
      :circles="summary"
      :height="'179px'"
    ></Four>
    <Details
      :test="test"
      type="cardiac"
    ></Details>
    <Resonance
      :test="test"
    ></Resonance>
    <Heart
      :title="'Heart Rate'"
      :width="'328px'"
      :test="test"
      :breat="true"
    ></Heart>
    <Four
      :title="'Aging Summary'"
      :circles="stress"
      :height="'196px'"
      :width="'308px'"
      :ml="'20px'"
    ></Four>
    <Comments
      v-if="test.procedureComment"
      :comment="test.procedureComment"
    ></Comments>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { vDiff } from '@/utilites/functions'

  import Measurements from '@/components/Measurements/Measurements'
  import Four from '@/components/Four/Four'
  import Adaptation from '@/components/Adaptation/Adaptation'
  import Heart from '@/components/Heart/Heart'
  import Comments from '@/components/Comments/Comments'
  import Details from '@/components/Details/Details'
  import Resonance from '@/components/Resonance/Resonance'

  import './UserCardiac.scss'

  export default {
    components: {
      Measurements, Four, Adaptation, Heart, Comments, Details, Resonance,
    },
    props: ['user', 'test'],
    computed: {
      ...mapState(['valueDiff', 'tabActive', 'tests']),
      summary() {
        let colorMeanScore = [
          {
            color: '#FBBC05',
            gap: [0, 30],
          },
          {
            color: '#FFFF00',
            gap: [31, 60],
          },
          {
            color: '#4ACE67',
            gap: [61, 100],
          },
        ]
        let colorConsistency = [
          {
            color: '#FBBC05',
            gap: [0, 20],
          },
          {
            color: '#FFFF00',
            gap: [21, 40],
          },
          {
            color: '#4ACE67',
            gap: [41, 100],
          },
        ]
        let colorMeanHR = [
          {
            color: '#FBBC05',
            gap: [30, 40],
          },
          {
            color: '#FFFF00',
            gap: [41, 50],
          },
          {
            color: '#4ACE67',
            gap: [51, 80],
          },
          {
            color: '#FFFF00',
            gap: [81, 90],
          },
          {
            color: '#FBBC05',
            gap: [91, 130],
          },
        ]
        let colorHrVariation = [
          {
            color: '#FBBC05',
            gap: [0, 10],
          },
          {
            color: '#FFFF00',
            gap: [11, 20],
          },
          {
            color: '#4ACE67',
            gap: [21, 50],
          },
        ]

        let valueMeanScore = Math.round(this.test.data.MeanScore)
        let valueConsistency = Math.round(this.test.data.Consistency)
        let valueMeanHR = Math.round(this.test.data.MeanHR)
        let valueHrVariation = Math.round(this.test.data.HrVariation)
        if (this.valueDiff) {
          colorMeanScore = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorConsistency = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorMeanHR = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorHrVariation = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]

          valueMeanScore = vDiff(this.tabActive, this.test, this.tests, 'MeanScore')
          valueConsistency = vDiff(this.tabActive, this.test, this.tests, 'Consistency')
          valueMeanHR = vDiff(this.tabActive, this.test, this.tests, 'MeanHR')
          valueHrVariation = vDiff(this.tabActive, this.test, this.tests, 'HrVariation')
        }

        return [
          {
            title: 'Resonance',
            margin: '30',
            color: colorMeanScore,
            value: valueMeanScore,
          },
          {
            title: 'Congruence',
            margin: '30',
            color: colorConsistency,
            value: valueConsistency,
          },
          {
            title: 'Mean HR',
            margin: '30',
            color: colorMeanHR,
            value: valueMeanHR,
          },
          {
            title: 'HR Variation',
            color: colorHrVariation,
            value: valueHrVariation,
          },
        ]
      },
      stress() {
        let colorAgingSpeed = [
          {
            color: '#4ACE67',
            gap: [0, 0.9],
          },
          {
            color: '#FFFF00',
            gap: [0.9, 1.1],
          },
          {
            color: '#FBBC05',
            gap: [1.1, 2.0],
          },
        ]
        let colorBioAge = [
          {
            color: '#0F89DA',
            gap: [0, 120],
          },
        ]
        let colorCalAge = [
          {
            color: '#0F89DA',
            gap: [0, 120],
          },
        ]

        let valueAgingSpeed = this.test.data.AgingSpeed.toFixed(1)
        let valueBioAge = Math.round(this.test.data.BioAge)
        let valueCalAge = Math.round(this.test.data.CalAge)

        if (this.valueDiff) {
          colorAgingSpeed = [
            {
              color: '#EB4335',
              gap: [-2, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 2],
            },
          ]
          colorBioAge = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorCalAge = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]

          valueAgingSpeed = vDiff(this.tabActive, this.test, this.tests, 'AgingSpeed', true)
          valueBioAge = vDiff(this.tabActive, this.test, this.tests, 'BioAge', true)
          valueCalAge = vDiff(this.tabActive, this.test, this.tests, 'CalAge', true)
        }

        return [
          {
            title: 'Aging Speed',
            margin: '30',
            color: colorAgingSpeed,
            value: valueAgingSpeed,
          },
          {
            title: 'Biological Age',
            margin: '30',
            color: colorBioAge,
            value: valueBioAge,
          },
          {
            title: 'Calendar Age',
            margin: '0px',
            color: colorCalAge,
            value: valueCalAge,
          },
        ]
      },
    },
  }
</script>
