<template>
  <div class="register-wrapper">
    <div class="register">
      <h2 class="register-header">
        Create account
      </h2>
      <div class="register-body">
        <div
          :class="['register-body-error', { 'register-body-error-active': error }]"
          v-html="errorMessage"
        >
        </div>
        <div class="register-body-desc">
          <span>*</span> - mandatory data entries
        </div>
        <form
          class="register-body-inputs"
          @submit.prevent="send"
        >
          <div class="inputs-wrapper">
            <Input
              :value="inputs.eg1"
              :name="'eg1'"
              :type="'text'"
              :placeholder="'Prefix'"
              :margin-bottom="37"
              :hint="'e.g. Dr'"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.first_name"
              :name="'first_name'"
              :type="'text'"
              :placeholder="'First name'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.last_name"
              :name="'last_name'"
              :type="'text'"
              :placeholder="'Last name'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.eg2"
              :name="'eg2'"
              :type="'text'"
              :placeholder="'Prefix'"
              :margin-bottom="37"
              :hint="'e.g. MD'"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.business"
              :name="'business'"
              :type="'text'"
              :placeholder="'Business name'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.email"
              :name="'email'"
              :type="'text'"
              :placeholder="'Contact email'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.phone"
              :name="'phone'"
              :type="'text'"
              :placeholder="'Contact phone'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.address"
              :name="'address'"
              :type="'text'"
              :placeholder="'Contact address'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.username"
              :name="'username'"
              :type="'text'"
              :placeholder="'Username'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.password"
              :name="'password'"
              :type="'password'"
              :placeholder="'Password'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.repeat"
              :name="'repeat'"
              :type="'password'"
              :placeholder="'Re-enter password'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
          </div>
          <div class="nav-wrapper">
            <Button
              :title="'Create'"
              @click="send"
            ></Button>
            <Button
              :title="'Cancel'"
              :mode="'cancel'"
              @click="$router.push('login')"
            ></Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

  import { validateEmail } from '@/utilites/functions'

  import { register } from '@/utilites/api'

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'

  import './Register.scss'

  export default {
    components: { Input, Button },
    data() {
      return {
        inputs: {
          eg1: '',
          first_name: '',
          last_name: '',
          eg2: '',
          business: '',
          email: '',
          phone: '',
          address: '',
          username: '',
          password: '',
          repeat: '',
        },
        error: true,
        errorMessage: 'Thank you for choosing the Body Health Analyzer! <br/> As a first step, you need to create your account record. Please fill the form below then click CREATE. ',
      }
    },
    methods: {
      inputChange(value) {
        this.inputs[value.name] = value.value
        this.error = false
      },
      inputCheck(input) {
        if (!this.inputs.first_name.trim() || !this.inputs.last_name.trim() || !this.inputs.email.trim() || !this.inputs.username.trim() || !this.inputs.password.trim() || !this.inputs.repeat.trim()) {
          this.error = true
          this.errorMessage = 'Fill in all required fields'
          return false
        } if (!validateEmail(this.inputs.email)) {
          this.error = true
          this.errorMessage = 'Please enter a valid email address'
          return false
        } if (this.inputs.password.length < 6) {
          this.error = true
          this.errorMessage = 'The password must contain at least 6 characters'
          return false
        } if (this.inputs.password !== this.inputs.repeat) {
          this.error = true
          this.errorMessage = 'Passwords don\'t match'
          return false
        }
        return true
      },
      send() {
        if (this.inputCheck()) {
          register(this.inputs)
            .then((response) => {
              if (!response.data) {
                this.errorMessage = response.errors.pop().message
                this.error = true
              } else {
                this.$router.push({
                  name: 'login',
                  params: { message: 'A new account was successfully created. Check your mailbox registered with the account to confirm the account activation' },
                })
              }
            })
        }
      },
    },
  }
</script>
