<template>
  <div class="user-data">
    <Measurements
      :title="'Body Measurements and Vitals'"
      :test="test"
    ></Measurements>
    <Four
      :title="'General health'"
      :circles="general"
    ></Four>
    <Adaptation
      :title="'Body Adaptation'"
      :values="[test.data.FR, test.data.SL]"
    ></Adaptation>
    <Four
      :title="'Stress and Adaptation'"
      :circles="stress"
      :height="'196px'"
      :width="'415px'"
    ></Four>
    <Heart
      :title="'Heart Rate'"
      :test="test"
    ></Heart>
    <Comments
      v-if="test.procedureComment"
      :comment="test.procedureComment"
    ></Comments>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { vDiff } from '@/utilites/functions'

  import Measurements from '@/components/Measurements/Measurements'
  import Four from '@/components/Four/Four'
  import Adaptation from '@/components/Adaptation/Adaptation'
  import Heart from '@/components/Heart/Heart'
  import Comments from '@/components/Comments/Comments'

  import './UserSingle.scss'

  export default {
    components: {
      Measurements,
      Four,
      Adaptation,
      Heart,
      Comments,
    },
    props: ['user', 'test'],
    computed: {
      ...mapState(['valueDiff', 'tabActive', 'tests']),
      stress() {
        let colorSL = [
          {
            color: '#EB4335',
            gap: [0, 25],
          },
          {
            color: '#FBBC05',
            gap: [26, 50],
          },
          {
            color: '#FFFF00',
            gap: [51, 75],
          },
          {
            color: '#4ACE67',
            gap: [76, 100],
          },
        ]
        let colorFR = [
          {
            color: '#EB4335',
            gap: [0, 25],
          },
          {
            color: '#FBBC05',
            gap: [26, 50],
          },
          {
            color: '#FFFF00',
            gap: [51, 75],
          },
          {
            color: '#4ACE67',
            gap: [76, 100],
          },
        ]
        let colorHSI = [
          {
            color: '#EB4335',
            gap: [0, 25],
          },
          {
            color: '#FBBC05',
            gap: [26, 50],
          },
          {
            color: '#FFFF00',
            gap: [51, 75],
          },
          {
            color: '#4ACE67',
            gap: [76, 100],
          },
        ]
        let colorSI = [
          {
            color: '#4ACE67',
            gap: [0, 150],
          },
          {
            color: '#FFFF00',
            gap: [151, 300],
          },
          {
            color: '#FBBC05',
            gap: [301, 1000],
          },
        ]
        let valueSL = Math.round(this.test.data.SL)
        let valueFR = Math.round(this.test.data.FR)
        let valueHSI = Math.round(this.test.data.HSI)
        let valueSI = Math.round(this.test.data.SL)
        if (this.valueDiff) {
          colorSL = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorFR = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorHSI = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorSI = [
            {
              color: '#EB4335',
              gap: [-500, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 500],
            },
          ]
          valueSL = vDiff(this.tabActive, this.test, this.tests, 'SL')
          valueFR = vDiff(this.tabActive, this.test, this.tests, 'FR')
          valueHSI = vDiff(this.tabActive, this.test, this.tests, 'HSI')
          valueSI = vDiff(this.tabActive, this.test, this.tests, 'SI')
        }

        return [
          {
            title: 'Adaptation Strain',
            margin: '30',
            color: colorSL,
            value: valueSL,
          },
          {
            title: 'Adaptation Resource',
            margin: '30',
            color: colorFR,
            value: valueFR,
          },
          {
            title: 'Adaptation Index',
            margin: '30',
            color: colorHSI,
            value: valueHSI,
          },
          {
            title: 'Stress Index',
            color: colorSI,
            value: valueSI,
          },
        ]
      },
      general() {
        let colorRMSSD = [
          {
            color: '#FBBC05',
            gap: [0, 20],
          },
          {
            color: '#FFFF00',
            gap: [21, 30],
          },
          {
            color: '#4ACE67',
            gap: [31, 100],
          },
        ]
        let colorSDNN = [
          {
            color: '#FBBC05',
            gap: [0, 20],
          },
          {
            color: '#FFFF00',
            gap: [21, 40],
          },
          {
            color: '#4ACE67',
            gap: [41, 100],
          },
        ]
        let colorAutonomicBalance = [
          {
            color: '#0F89DA',
            gap: [-100, -20],
          },
          {
            color: '#4ACE67',
            gap: [-21, 20],
          },
          {
            color: '#FBBC05',
            gap: [21, 100],
          },
        ]
        let colorSI = [
          {
            color: '#4ACE67',
            gap: [0, 150],
          },
          {
            color: '#FFFF00',
            gap: [151, 300],
          },
          {
            color: '#FBBC05',
            gap: [301, 1000],
          },
        ]
        let valueRMSSD = Math.round(this.test.data.RMSSD)
        let valueSDNN = Math.round(this.test.data.SDNN)
        let valueAutonomicBalance = Math.round(this.test.data.AutonomicBalance)
        let valueSI = Math.round(this.test.data.SL)
        if (this.valueDiff) {
          colorRMSSD = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorSDNN = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorAutonomicBalance = [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
          colorSI = [
            {
              color: '#EB4335',
              gap: [-500, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 500],
            },
          ]
          valueRMSSD = vDiff(this.tabActive, this.test, this.tests, 'RMSSD')
          valueSDNN = vDiff(this.tabActive, this.test, this.tests, 'SDNN')
          valueAutonomicBalance = vDiff(this.tabActive, this.test, this.tests, 'AutonomicBalance')
          valueSI = vDiff(this.tabActive, this.test, this.tests, 'SI')
        }

        return [
          {
            title: 'Stress Index',
            margin: '30',
            color: colorSI,
            value: valueSI,
          },
          {
            title: 'Vagal Index',
            margin: '30',
            color: colorRMSSD,
            value: valueRMSSD,
          },
          {
            title: 'HRV Index',
            margin: '30',
            color: colorSDNN,
            value: valueSDNN,
          },
          {
            title: 'Autonomic Balance',
            color: colorAutonomicBalance,
            value: valueAutonomicBalance,
          },
        ]
      },
    },
  }
</script>
