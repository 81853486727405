<template>
  <div class="measurements">
    <div class="measurements-header">
      {{ title }}
    </div>
    <div
      v-if="test"
      class="measurements-body"
    >
      <CircleGraph
        :margin="'25'"
        :title="'Resting HR'"
        :sign="valueDiff"
        :color="colorRest"
        :value="heartRate"
      ></CircleGraph>
      <CircleGraph
        :margin="'25'"
        :title="'SpO2'"
        :sign="valueDiff"
        :color="colorSpO2"
        :value="spO2"
      ></CircleGraph>
      <div class="measurements-body-person">
        <div class="column-left">
          <div class="column-item">
            Height: <span>{{ height }}</span>
          </div>
          <div class="column-item">
            Weight:    <span>{{ weight }}</span>
          </div>
          <div class="column-item">
            BMI:       <span>{{ bmi }}</span>
          </div>
        </div>
        <div class="column-right">
          <div class="column-item">
            Blood Pressure:    <span>{{ pressure }}</span>
          </div>
          <div class="column-item">
            Blood Glucose:    <span>{{ glucose }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CircleGraph from '@/components/CircleGraph/CircleGraph'
  import { vDiff } from '@/utilites/functions'

  import './Measurements.scss'

  export default {
    components: { CircleGraph },
    props: ['title', 'test'],
    data() {
      return {

      }
    },
    computed: {
      ...mapState(['valueDiff', 'tabActive', 'tests']),
      heartRate() {
        if (!this.test) return 0
        if (this.valueDiff) {
          return vDiff(this.tabActive, this.test, this.tests, 'HeartRate')
        }
        return +this.test.data.HeartRate.toFixed(0)
      },
      spO2() {
        if (!this.test) return 0
        if (this.valueDiff) {
          return vDiff(this.tabActive, this.test, this.tests, 'SpO2')
        }
        return +this.test.data.SpO2.toFixed(0)
      },
      height() {
        const h = this.test.body?.data?.Height
        if (h) {
          const d = Math.trunc(h / 12)
          const f = h - (d * 12)
          return `${d}-${f}`
        }
        return '---'
      },
      weight() {
        return this.test?.compose?.data?.Weight || '---'
      },
      bmi() {
        return this.test?.compose?.data?.BMI.toFixed(1) || '---'
      },
      pressure() {
        if (this.test?.pressure?.data) {
          return `${this.test.pressure.data.Sys}/${this.test?.pressure.data.Dia}`
        }
        return '---'
      },
      glucose() {
        return this.test?.glucose?.data['Blood glucose']?.toFixed(0) || '---'
      },
      colorRest() {
        if (this.valueDiff) {
          return [
            {
              color: '#EB4335',
              gap: [-100, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 100],
            },
          ]
        }
        return [
          {
            color: '#FBBC05',
            gap: [30, 40],
          },
          {
            color: '#FFFF00',
            gap: [41, 50],
          },
          {
            color: '#4ACE67',
            gap: [51, 80],
          },
          {
            color: '#FFFF00',
            gap: [81, 90],
          },
          {
            color: '#FBBC05',
            gap: [91, 130],
          },
        ]
      },
      colorSpO2() {
        if (this.valueDiff) {
          return [
            {
              color: '#EB4335',
              gap: [-500, 0],
            },
            {
              color: '#4ACE67',
              gap: [0, 500],
            },
          ]
        }
        return [
          {
            color: '#FBBC05',
            gap: [0, 90],
          },
          {
            color: '#FFFF00',
            gap: [91, 94],
          },
          {
            color: '#4ACE67',
            gap: [95, 100],
          },
        ]
      },
    },
  }
</script>
