<template>
  <div class="user-base">
    <div :class="['user-base-user', { 'user-base-user print': print }]">
      <div
        v-if="user"
        class="user-base-user-image"
      >
        <Female v-if="user.client.gender === 'Female'"></Female>
        <Male v-if="user.client.gender === 'Male'"></Male>
      </div>
      <div class="user-base-user-info">
        {{ user.lastName }}, {{ user.firstName }}
        <span
          v-if="print"
          style="margin-left: 10px;"
        >DOB (Age): {{ user.client.dateOfBirth }} ({{ ageCount }})</span>
        <span v-else>(Age {{ ageCount }})</span>
        <div v-if="print">
          <span>Gender: {{ user.client.gender }}</span>
          <span style="margin-left: 10px;">Health Notes: {{ user.client.heathNotes }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="test"
      :class="['user-base-nav', { 'user-base-nav-open': openedMenu }]"
    >
      <div
        v-if="!isHistory && !isPrePost"
        class="user-base-nav-btn"
        :style="{ opacity: diff ? 1 : .4 }"
        @click="$emit('changeDiff')"
      >
        <span class="user-base-nav-btn-icon"><Diff></Diff></span>
        <span class="user-base-nav-btn-text">Value/Diff</span>
      </div>
      <div
        v-if="!isHistory && !isPrePost"
        class="user-base-nav-btn"
        :style="{ opacity: test.show ? 1 : .4 }"
        @click="$emit('changeShow', test.id)"
      >
        <span class="user-base-nav-btn-icon"><Eye></Eye></span>
        <span class="user-base-nav-btn-text">Show/Hide</span>
      </div>
      <div
        class="user-base-nav-btn"
        @click="printInit"
      >
        <span class="user-base-nav-btn-icon"><Download></Download></span>
        <span class="user-base-nav-btn-text">Download</span>
      </div>
      <div
        class="user-base-nav-btn"
        @click="printInit('print')"
      >
        <span class="user-base-nav-btn-icon"><Print></Print></span>
        <span class="user-base-nav-btn-text">Print</span>
      </div>
      <div
        v-if="!isHistory && isExcel"
        class="user-base-nav-btn"
        @click="excelHandler"
      >
        <span class="user-base-nav-btn-icon"><Export></Export></span>
        <span class="user-base-nav-btn-text">Export</span>
      </div>
      <div
        v-if="!isHistory && !isPrePost"
        class="user-base-nav-btn"
        @click="$emit('commentController')"
      >
        <span class="user-base-nav-btn-icon"><Comment></Comment></span>
        <span class="user-base-nav-btn-text">Comment</span>
      </div>
      <div
        v-if="!isHistory && !isPrePost"
        class="user-base-nav-btn"
        @click="$emit('deleteController')"
      >
        <span class="user-base-nav-btn-icon"><Archive></Archive></span>
        <span class="user-base-nav-btn-text">Delete</span>
      </div>
    </div>
    <div
      v-if="!print"
      class="user-base-mobileonly user-base-nav-fab"
    >
      <FabMenu
        v-show="!openedMenu"
        @click="toggleNavSub"
      ></FabMenu>
      <FabClose
        v-show="openedMenu"
        @click="toggleNavSub"
      ></FabClose>
    </div>
  </div>
</template>

<script>

  import html2canvas from 'html2canvas'
  import { jsPDF } from 'jspdf'
  import { mapState, mapMutations } from 'vuex'

  import Diff from '@/assets/img/base/diff.svg'
  import Eye from '@/assets/img/base/eye.svg'
  import Download from '@/assets/img/base/download.svg'
  import Print from '@/assets/img/base/print.svg'
  import Export from '@/assets/img/base/export.svg'
  import Comment from '@/assets/img/base/comment.svg'
  import Archive from '@/assets/img/archive.svg'
  import { xls } from '@/utilites/excel'
  import { date } from '@/utilites/functions'

  import Male from '@/assets/img/male-min.svg'
  import Female from '@/assets/img/female-min.svg'
  import FabMenu from '@/assets/img/mobile_menu.svg'
  import FabClose from '@/assets/img/mobile_menu_close.svg'

  import './UserBase.scss'

  export default {
    components: {
      Diff, Eye, Download, Print, Export, Comment, Female, Male, Archive, FabMenu, FabClose,
    },
    props: ['user', 'test', 'diff', 'tab'],
    data() {
      return {
        openedMenu: false,
        age: 0,
        excelName: '',
      }
    },
    computed: {
      ...mapState(['print', 'pdf']),
      ...mapState(['historyMinDate', 'historyMaxDate']),
      ageCount() {
        return date(this.user.client.dateOfBirth, 'b')
      },
      isHistory() {
        return this.$route.name === 'history'
      },
      isPrePost() {
        return this.$route.name === 'prepost'
      },
      isExcel() {
        if (this.$route.name !== 'prepost') return true
        return this.test != null && this.test.pre != null && this.test.post != null
      },
    },
    methods: {
      ...mapMutations(['setPrint', 'setPdf']),
      toggleNavSub() {
        this.openedMenu = !this.openedMenu
      },
      printAction(mode) {
        if (mode === 'print') {
          window.print()
          this.$nextTick(() => {
            this.setPrint(false)
          })
          return
        }
        document.querySelector('.user-caregiver').style.display = 'none'
        html2canvas(document.querySelector('.user')).then((canvas) => {
          const orientation = 'h'
          // const orientation = 'l'

          const doc = jsPDF({ orientation })

          const screenshot = canvas.toDataURL('image/png')

          let testSeed = this.test
          if (this.$route.path.includes('post')) {
            testSeed = this.test.pre
          }

          let dateSeed = new Date(testSeed.testedDate * 1000)
          if (!dateSeed) {
            dateSeed = new Date(testSeed.createdAt)
          }
          if (this.$route.path.includes('history')) {
            dateSeed = new Date()
          }

          let date = dateSeed.getDate()
          let month = dateSeed.getMonth() + 1
          const year = dateSeed.getFullYear()
          let hour = dateSeed.getHours()
          let minute = dateSeed.getMinutes()
          if (month < 10) month = `0${month}`
          if (date < 10) date = `0${date}`
          if (hour < 10) hour = `0${hour}`
          if (minute < 10) minute = `0${minute}`
          let fileDate = `${month}_${date}_${year}_${hour}_${minute}`

          if (this.$route.path.includes('history')) {
            let dateOne = this.historyMinDate.getDate()
            let monthOne = this.historyMinDate.getMonth() + 1
            const yearOne = this.historyMinDate.getFullYear()
            if (monthOne < 10) monthOne = `0${monthOne}`
            if (dateOne < 10) dateOne = `0${dateOne}`

            let dateTwo = this.historyMaxDate.getDate()
            let monthTwo = this.historyMaxDate.getMonth() + 1
            const yearTwo = this.historyMaxDate.getFullYear()
            if (monthTwo < 10) monthTwo = `0${monthTwo}`
            if (dateTwo < 10) dateTwo = `0${dateTwo}`

            fileDate = `${monthOne}_${dateOne}_${yearOne}__${monthTwo}_${dateTwo}_${yearTwo}`
          }

          let name = ''
          if (this.$route.path.includes('single')) {
            name = `Health_Single_${fileDate}`
          } else if (this.$route.path.includes('post')) {
            name = `Health_Pre_Post_${fileDate}`
          } else if (this.$route.path.includes('cardiac')) {
            name = `Cardiac_Resonance_${fileDate}`
          } else if (this.$route.path.includes('breatwork')) {
            name = `Breathwork_${fileDate}`
          } else if (this.$route.path.includes('history')) {
            name = `History_${fileDate}`
          }

          let width = 208
          if (orientation === 'l') width = 294

          const ratio = canvas.width / width
          const height = canvas.height / ratio
          document.querySelector('.user-caregiver').style.display = 'block'
          html2canvas(document.querySelector('.user-caregiver')).then((canvasCareg) => {
            this.$nextTick(() => {
              this.setPrint(false)
              this.setPdf(false)
            })
            const screenshotCareg = canvasCareg.toDataURL('image/png')
            const ratioCareg = canvasCareg.width / width
            const heightCareg = canvasCareg.height / ratioCareg

            doc.addImage(screenshot, 'PNG', 1, 4, width, height)
            doc.addImage(screenshotCareg, 'PNG', 1, 276, width, heightCareg)
            doc.save(`${name}.pdf`)
            this.$emit('pushController', 'A new PDF report was successfully saved')
          })
        })
      },
      printInit(mode) {
        this.setPrint(true)
        if (mode !== 'print') {
          this.setPdf(true)
        }
        this.$nextTick(() => {
          if (this.isHistory) {
            // history canvas graphic rerender fix
            setTimeout(() => {
              this.printAction(mode)
            }, 500)
          } else {
            this.printAction(mode)
          }
        })
      },
      excelHandler() {
        let testSeed = this.test
        if (this.$route.path.includes('post')) {
          testSeed = this.test.pre
        }

        let dateSeed = new Date(testSeed.testedDate * 1000)
        if (!dateSeed) {
          dateSeed = new Date(testSeed.createdAt)
        }

        let date = dateSeed.getDate()
        let month = dateSeed.getMonth() + 1
        const year = dateSeed.getFullYear()
        let hour = dateSeed.getHours()
        let minute = dateSeed.getMinutes()
        if (month < 10) month = `0${month}`
        if (date < 10) date = `0${date}`
        if (hour < 10) hour = `0${hour}`
        if (minute < 10) minute = `0${minute}`
        const fileDate = `${month}_${date}_${year}_${hour}_${minute}`

        let name
        if (this.$route.path.includes('single')) {
          name = `Health_Single_${fileDate}`
          xls(name, this.test, 'single')
        } else if (this.$route.path.includes('post')) {
          name = `Health_Pre_Post_${fileDate}`
          xls(name, this.test, 'prepost')
        } else if (this.$route.path.includes('cardiac')) {
          name = `Cardiac_Resonance_${fileDate}`
          xls(name, this.test, 'cardiac')
        } else if (this.$route.path.includes('breatwork')) {
          name = `Breathwork_${fileDate}`
          xls(name, this.test, 'breatwork')
        }
      },
    },
  }
</script>
