export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).trim().toLowerCase())
}

export function date(data, flag) {
  if (data === null) {
    return null
  }
  if (data < 1000000000000) data *= 1000

  let tempDateY
  let tempDateM
  let tempDateD
  let tempDate
  if (flag === 'b') {
    tempDate = new Date(data)
    return new Date().getFullYear() - tempDate.getFullYear()
  } if (flag === 'td') {
    tempDate = new Date(data)
    let d = 'AM'
    let hours
    if (tempDate.getHours() > 12) {
      d = 'PM'
      hours = tempDate.getHours() - 12
    } else {
      hours = tempDate.getHours()
    }
    return `${tempDate.getMonth() + 1}/${tempDate.getDate()}/${tempDate.getFullYear()}&nbsp;&nbsp;&nbsp;${hours}:${tempDate.getMinutes()} ${d}`
  } if (flag === 'xd') {
    tempDate = new Date(data)
    return `${tempDate.getMonth() + 1}/${tempDate.getDate()}/${tempDate.getFullYear()}`
  } if (flag === 'xt') {
    tempDate = new Date(data)
    let d = 'AM'
    let hours
    if (tempDate.getHours() > 12) {
      d = 'PM'
      hours = tempDate.getHours() - 12
    } else {
      hours = tempDate.getHours()
    }
    return `${hours}:${tempDate.getMinutes()} ${d}`
  }
}

export function vDiff(type, test, tests, key, fixedOne = false) {
  if (tests[type] == null) {
    return 0
  }
  const testInd = tests[type].findIndex((t) => t.id === test.id)
  if (testInd < 0) return 0

  const testPrev = tests[type][testInd + 1]
  if (testPrev == null) return 0
  if (test.data[key] == null || testPrev.data[key] == null) return 0
  if (fixedOne) return +(test.data[key] - testPrev.data[key]).toFixed(1)

  return +(test.data[key] - testPrev.data[key]).toFixed(0)
}
