<template>
  <div class="account-wrapper">
    <div class="account">
      <h2 class="account-header">
        <Back @click="$router.push('dashboard')"></Back> Account
      </h2>
      <div class="account-body">
        <div
          v-if="errorMessage"
          :class="[
            'account-body-error',
            { 'account-body-error-active': error },
          ]"
          v-html="errorMessage"
        ></div>
        <div class="account-body-desc">
          <span>*</span> - mandatory data entries
        </div>
        <form
          class="account-body-inputs"
          @submit.prevent="send"
        >
          <div class="inputs-wrapper">
            <Input
              :value="inputs.eg1"
              :name="'eg1'"
              :type="'text'"
              :placeholder="'Prefix'"
              :margin-bottom="37"
              :hint="'e.g. Dr'"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.first_name"
              :name="'first_name'"
              :type="'text'"
              :placeholder="'First name'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.last_name"
              :name="'last_name'"
              :type="'text'"
              :placeholder="'Last name'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.eg2"
              :name="'eg2'"
              :type="'text'"
              :placeholder="'Prefix'"
              :margin-bottom="37"
              :hint="'e.g. MD'"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.business"
              :name="'business'"
              :type="'text'"
              :placeholder="'Business name'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.email"
              :name="'email'"
              :type="'text'"
              :placeholder="'Contact email'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.phone"
              :name="'phone'"
              :type="'text'"
              :placeholder="'Contact phone'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.address"
              :name="'address'"
              :type="'text'"
              :placeholder="'Contact address'"
              :margin-bottom="37"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.username"
              :name="'username'"
              :type="'text'"
              :placeholder="'Username'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
          </div>

          <h2>Change password</h2>
          <div class="inputs-wrapper">
            <Input
              :value="inputs.current_password"
              :name="'current_password'"
              :type="'password'"
              :placeholder="'Enter current password'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.password"
              :name="'password'"
              :type="'password'"
              :placeholder="'Password'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
            <Input
              :value="inputs.repeat"
              :name="'repeat'"
              :type="'password'"
              :placeholder="'Re-enter password'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
              @focus="error = false"
              @blur="inputCheck"
            ></Input>
          </div>
          <div class="nav-wrapper">
            <Button
              :title="'Save'"
              @click="send"
            ></Button>
            <Button
              :title="'Cancel'"
              :margin="0"
              :mode="'cancel'"
              @click="$router.push('dashboard')"
            ></Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import { validateEmail } from '@/utilites/functions'

  import { updateAccount } from '@/utilites/api'

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'
  import Back from '@/assets/img/arrow_back.svg'

  import './Account.scss'

  export default {
    components: { Input, Button, Back },
    data() {
      return {
        inputs: {
          id: '',
          eg1: '',
          first_name: '',
          last_name: '',
          eg2: '',
          business: '',
          email: '',
          phone: '',
          address: '',
          username: '',
          password: '',
          current_password: '',
          repeat: '',
        },
        error: true,
        errorMessage: '',
      }
    },
    computed: {
      ...mapState(['user', 'token']),
    },
    mounted() {
      const user = this.user
      this.inputs = {
        id: parseInt(this.user.id),
        eg1: user.namePrefix,
        first_name: user.firstName,
        last_name: user.lastName,
        eg2: user.nameSuffix,
        business: user.caregiver.businessName,
        email: user.email,
        phone: user.phone,
        address: user.address,
        username: user.username,
      }
    },
    methods: {
      ...mapMutations(['updateUser']),
      inputChange(value) {
        this.inputs[value.name] = value.value
        this.error = false
      },
      inputCheck() {
        if (
          !this.inputs.first_name.trim()
          || !this.inputs.last_name.trim()
          || !this.inputs.eg1.trim()
          || !this.inputs.eg2.trim()
          || !this.inputs.phone.trim()
          || !this.inputs.email.trim()
          || !this.inputs.username.trim()
        ) {
          this.error = true
          this.errorMessage = 'Fill in all required fields'
          return false
        }
        if (!validateEmail(this.inputs.email)) {
          this.error = true
          this.errorMessage = 'Please enter a valid email address'
          return false
        }
        if (this.inputs.password) {
          if (this.inputs.password.length < 6) {
            this.error = true
            this.errorMessage = 'The password must contain at least 6 characters'
            return false
          }
          if (this.inputs.password !== this.inputs.repeat) {
            this.error = true
            this.errorMessage = 'Passwords don\'t match'
            return false
          }
        }
        return true
      },
      send() {
        if (this.inputCheck()) {
          updateAccount(this.$attrs.token, this.inputs).then((response) => {
            if (!response.data) {
              this.errorMessage = response.errors.pop().message
              this.error = true
            } else {
              if (response.data.updateUser) {
                this.updateUser({ user: response.data.updateUser })
              }

              this.$router.push({
                name: 'dashboard',
                params: {
                  message: 'The account was successfully updated.',
                },
              })
            }
          })
        }
      },
    },
  }
</script>
