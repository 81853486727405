<template>
  <div class="configuration-edit-wrapper">
    <div class="configuration-edit">
      <h2 class="configuration-edit-header">
        <Back @click="$router.push('/configuration')"></Back> Breath pacer model
        preset
      </h2>
      <div class="configuration-edit-body">
        <div class="help">
          Adjust the breath pacer guide time setting and click SAVE
        </div>
        <div
          v-if="errorMessage"
          :class="[
            'configuration-edit-body-error',
            { 'configuration-edit-body-error-active': error },
          ]"
          v-html="errorMessage"
        ></div>
        <div class="configuration-edit-body-desc">
          <span>*</span> - mandatory data entries
        </div>
        <form
          class="configuration-edit-body-inputs"
          @submit.prevent="send"
        >
          <div class="inputs-wrapper">
            <Input
              :value="caregiverGuide.name"
              :name="'preset_name'"
              :type="'text'"
              :placeholder="'Preset name'"
              :margin-bottom="37"
              :required="true"
              @inputChange="inputChange"
            ></Input>
            <Input
              :value="`${breathPeriod} sec`"
              :readonly="true"
              :name="'breath_period'"
              :type="'text'"
              :placeholder="'Breath period'"
              :margin-bottom="37"
              :always-active="true"
            ></Input>
            <Input
              :value="`${breathRate} br/min`"
              :readonly="true"
              :name="'breath_rate'"
              :type="'text'"
              :placeholder="'Breath rate'"
              :margin-bottom="37"
              :always-active="true"
            ></Input>
          </div>
        </form>
        <div class="metronome metronome-graphs">
          <div
            class="metronome-item inhale"
            :style="{ flex: caregiverGuide.inhaleTime }"
          >
            <div class="line"></div>
            <div class="label">
              Ihale
            </div>
          </div>
          <div
            class="metronome-item hold"
            :style="{
              flex: caregiverGuide.holdTime,
              display: caregiverGuide.holdTime === 0 ? 'none' : 'flex',
            }"
          >
            <div class="line"></div>
            <div class="label">
              Hold
            </div>
          </div>
          <div
            class="metronome-item exhale"
            :style="{ flex: caregiverGuide.exhaleTime }"
          >
            <div class="line"></div>
            <div class="label">
              Exhale
            </div>
          </div>
          <div
            class="metronome-item pause"
            :style="{
              flex: caregiverGuide.pauseTime,
              display: caregiverGuide.pauseTime === 0 ? 'none' : 'flex',
            }"
          >
            <div class="line"></div>
            <div class="label">
              Pause
            </div>
          </div>
        </div>
        <div class="metronome metronome-buttons">
          <div class="metronome-item-bottom">
            <div class="metronome-button">
              <div class="title">
                Ihale
                <div class="metronome-button-flex">
                  <MinusIcon @click.native="minus('inhaleTime')">
                  </MinusIcon>
                  <div class="number">
                    {{ parseFloat(caregiverGuide.inhaleTime).toFixed(1) }}
                  </div>
                  <PlusIcon @click.native="plus('inhaleTime')">
                  </PlusIcon>
                </div>
              </div>
            </div>
          </div>
          <div class="metronome-item-bottom">
            <div class="metronome-button">
              <div class="title">
                Hold
                <div class="metronome-button-flex">
                  <MinusIcon @click.native="minus('holdTime')">
                  </MinusIcon>
                  <div class="number">
                    {{ parseFloat(caregiverGuide.holdTime).toFixed(1) }}
                  </div>
                  <PlusIcon @click.native="plus('holdTime')">
                  </PlusIcon>
                </div>
              </div>
            </div>
          </div>
          <div class="metronome-item-bottom">
            <div class="metronome-button">
              <div class="title">
                Exhale
                <div class="metronome-button-flex">
                  <MinusIcon @click.native="minus('exhaleTime')">
                  </MinusIcon>
                  <div class="number">
                    {{ parseFloat(caregiverGuide.exhaleTime).toFixed(1) }}
                  </div>
                  <PlusIcon @click.native="plus('exhaleTime')">
                  </PlusIcon>
                </div>
              </div>
            </div>
          </div>
          <div class="metronome-item-bottom">
            <div class="metronome-button">
              <div class="title">
                Pause
                <div class="metronome-button-flex">
                  <MinusIcon @click.native="minus('pauseTime')">
                  </MinusIcon>
                  <div class="number">
                    {{ parseFloat(caregiverGuide.pauseTime).toFixed(1) }}
                  </div>
                  <PlusIcon @click.native="plus('pauseTime')">
                  </PlusIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-wrapper">
          <Button
            title="Save"
            :width="154"
            :margin="20"
            :disabled="caregiverGuide == null || loading"
            @click="save"
          ></Button>
          <Button
            title="Reset"
            :disabled="caregiverGuide == null || loading"
            :width="154"
            :mode="'cancel'"
            @click="reset"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import { validateEmail } from '@/utilites/functions'

  import { updateAccount } from '@/utilites/api'

  import Input from '@/components/Input/Input'
  import Button from '@/components/Button/Button'
  import Back from '@/assets/img/arrow_back.svg'
  import MinusIcon from './MinusIcon'
  import PlusIcon from './PlusIcon'

  import {
    getCaregiverGuide,
    createCaregiverGuide,
    updateCaregiverGuide,
    deleteCaregiverGuide,
  } from '../../utilites/api'

  import './ConfigurationEdit.scss'

  export default {
    components: {
      Input,
      Button,
      Back,
      MinusIcon,
      PlusIcon,
    },
    data() {
      return {
        error: true,
        errorMessage: '',
        caregiverGuide: {
          id: null,
          name: '',
          inhaleTime: 3.0,
          holdTime: 1.0,
          exhaleTime: 4.0,
          pauseTime: 2.0,
        },
        backupCaregiverGuide: {
          name: '',
          inhaleTime: 3.0,
          holdTime: 1.0,
          exhaleTime: 4.0,
          pauseTime: 2.0,
        },
        settings: {
          inhaleTime: {
            min: 1,
            max: 30,
            step: 0.5,
          },
          holdTime: {
            min: 0,
            max: 30,
            step: 0.5,
          },
          exhaleTime: {
            min: 1,
            max: 30,
            step: 0.5,
          },
          pauseTime: {
            min: 0,
            max: 30,
            step: 0.5,
          },
        },
        loading: true,
      }
    },
    computed: {
      ...mapState(['user', 'token']),
      breathPeriod() {
        if (this.loading) return '...'
        const sum
          = this.caregiverGuide.inhaleTime
            + this.caregiverGuide.holdTime
            + this.caregiverGuide.exhaleTime
            + this.caregiverGuide.pauseTime
        return sum
      },
      breathRate() {
        if (this.loading) return '...'
        let rate = 60 / this.breathPeriod
        rate = Math.round((rate + Number.EPSILON) * 10) / 10
        return rate
      },
      isCreate() {
        return this.$route.params.id === 'new'
      },
      isEdit() {
        return this.$route.params.id !== 'new'
      },
      id() {
        return this.$route.params.id
      },
    },
    mounted() {
      this.loading = true
      if (this.isEdit) {
        getCaregiverGuide(this.token, {
          caregiverId: this.user.caregiver.id,
          id: this.id,
        })
          .then((response) => {
            if (!response.data) {
              this.errorMessage = response.errors.pop().message
              this.error = true
              this.loading = false
              return
            }

            const cGuide = response.data.caregiverGuides.nodes.find(
              (cGuide) => cGuide.id === this.id,
            )

            Object.keys(this.caregiverGuide).forEach((key) => {
              this.caregiverGuide[key] = cGuide[key]
            })
            this.backupCaregiverGuide = JSON.parse(
              JSON.stringify(this.caregiverGuide),
            )
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    methods: {
      ...mapMutations(['updateUser']),
      plus(type) {
        if (
          this.caregiverGuide[type] + this.settings[type].step
          > this.settings[type].max
        ) {
          this.caregiverGuide[type] = this.settings[type].max
          return
        }
        this.caregiverGuide[type] += this.settings[type].step
      },
      minus(type) {
        if (
          this.caregiverGuide[type] - this.settings[type].step
          < this.settings[type].min
        ) {
          this.caregiverGuide[type] = this.settings[type].min
          return
        }
        this.caregiverGuide[type] -= this.settings[type].step
      },
      save() {
        this.loading = true
        if (this.isEdit) {
          updateCaregiverGuide(this.token, this.caregiverGuide)
            .then((response) => {
              if (!response.data) {
                this.errorMessage = response.errors.pop().message
                this.error = true
                this.loading = false
                return
              }
              const cGuide = response.data.updateGuide

              Object.keys(this.caregiverGuide).forEach((key) => {
                this.caregiverGuide[key] = cGuide[key]
              })
              Object.keys(this.caregiverGuide).forEach((key) => {
                this.backupCaregiverGuide[key] = this.caregiverGuide[key]
              })
              this.$router.push('/configuration')
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          createCaregiverGuide(this.token, this.caregiverGuide)
            .then((response) => {
              if (!response.data) {
                this.errorMessage = response.errors.pop().message
                this.error = true
                this.loading = false
                return
              }

              const cGuide = response.data.createGuide

              Object.keys(this.caregiverGuide).forEach((key) => {
                this.caregiverGuide[key] = cGuide[key]
              })
              Object.keys(this.caregiverGuide).forEach((key) => {
                this.backupCaregiverGuide[key] = this.caregiverGuide[key]
              })
              this.$router.push('/configuration')
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      reset() {
        Object.keys(this.backupCaregiverGuide).forEach((key) => {
          this.caregiverGuide[key] = this.backupCaregiverGuide[key]
        })
      },
      inputCheck(input) {
        if (!this.caregiverGuide.name.trim()) {
          this.error = true
          this.errorMessage = 'Fill in all required fields'
          return false
        }
        return true
      },
      inputChange(value) {
        this.caregiverGuide.name = value.value
        this.error = false
      },
    },
  }
</script>
<style lang="scss">
.metronome {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-bottom: 66px;

  &-graphs {
    height: 186px;
  }

  &-buttons {
    flex-direction: column;
  }
}

.metronome-item {
  flex: 1;
  position: relative;
  transition: flex 0.3s ease;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(33, 33, 33, 0.5);
  min-width: 50px;

  &-bottom {
    flex: 1;
    flex-direction: column;
    position: relative;
    transition: flex 0.3s ease;
    // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    // background-color: rgba(33, 33, 33, 0.5);

    .metronome-button {
      text-align: center;
      width: 100%;

      .title {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        padding: 0;
      }

      .metronome-button-flex {
        margin-top: 4px;
        display: flex;
        justify-content: center;
        min-width: 114px;

        svg {
          cursor: pointer;
        }

        .number {
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          margin-right: 26px;
          margin-left: 26px;
        }
      }
    }
  }

  &:not(:last-child) {
    border: solid #303030 6px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }

  &.inhale .line {
    background: linear-gradient(
      to bottom right,
      transparent calc(50% - 2px),
      #09f calc(50% - 1px),
      #09f calc(50% + 1px),
      transparent calc(50% + 2px)
    );
    height: 100%;
    width: calc(100% + 6px);
    margin-right: -6px;
    position: absolute;
    top: 0;
  }

  &.hold .line {
    background: linear-gradient(
      to top,
      transparent calc(100% - 3px),
      #09f calc(100% - 2px)
    );
    height: 100%;
    width: calc(100%);
    position: absolute;
    top: 0;
  }

  &.exhale .line {
    background: linear-gradient(
      to top right,
      transparent calc(50% - 2px),
      #09f calc(50% - 1px),
      #09f calc(50% + 1px),
      transparent calc(50% + 2px)
    );
    height: 100%;
    width: calc(100% + 6px);
    margin-left: -6px;
    position: absolute;
    top: 0;
  }

  &.pause .line {
    background: linear-gradient(
      to bottom,
      transparent calc(100% - 3px),
      #09f calc(100% - 2px)
    );
    height: 100%;
    width: calc(100% + 6px);
    position: absolute;
    top: 0;
    margin-left: -6px;
  }

  .label {
    height: 100%;
    direction: rtl;
    transform: rotate(-90deg);
    position: absolute;
    font-size: 14px;
    left: 86px;
    color: rgba(255, 255, 255, 0.5);
  }
}

@media (min-width: 1024px) {
  .metronome {
    &-buttons {
      flex-direction: row;
    }

    &-graphs {
      height: 186px;
    }

    &-item {
      &-bottom {
        flex: 1;
        min-width: 148px;
        position: relative;
        transition: flex 0.3s ease;
        // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
        // background-color: rgba(33, 33, 33, 0.5);

        .metronome-button {
          text-align: center;
          width: 100%;
          position: absolute;

          .title {
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 45px;
            padding: 0;
          }

          .metronome-button-flex {
            margin-top: 4px;
            display: flex;
            justify-content: center;
            min-width: 114px;

            svg {
              cursor: pointer;
            }

            .number {
              color: #fff;
              font-size: 18px;
              font-weight: 600;
              margin-right: 26px;
              margin-left: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
