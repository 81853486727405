<template>
  <div class="mobile-head">
    <span
      v-if="!searchMode"
      class="mobile-head-menu"
      @click="$emit('open')"
    >
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H18V2.01562H0V0ZM0 6.98438V5.01562H18V6.98438H0ZM0 12V9.98438H18V12H0Z"
          fill="white"
        />
      </svg>
    </span>
    <span v-if="!searchMode">{{ title }}</span>

    <span
      v-if="searchMode"
      class="mobile-head-menu"
      @click="searchMode = false"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0156 11.0156V12.9844H7.82812L13.4062 18.6094L12 20.0156L3.98438 12L12 3.98438L13.4062 5.39062L7.82812 11.0156H20.0156Z"
          fill="white"
          fill-opacity="0.9"
        />
      </svg>

    </span>
    <span
      v-if="searchMode"
      class="mobile-head-input"
    >
      <input
        ref="refsearch"
        v-model="searchText"
        type="text"
        :placeholder="searchingThing"
      >
    </span>

    <span
      v-if="!searchMode"
      class="mobile-head-search"
      @click="search"
    >

      <svg
        v-if="searchingThing"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.502 11.0002H11.708L11.432 10.7262C12.407 9.58921 13 8.11521 13 6.50021C13 2.91021 10.09 0.000211716 6.5 0.000211716C2.91 0.000211716 0 2.91021 0 6.50021C0 10.0902 2.91 13.0002 6.5 13.0002C8.115 13.0002 9.588 12.4082 10.725 11.4342L11.001 11.7082V12.5002L15.999 17.4912L17.49 16.0002L12.502 11.0002ZM6.5 11.0002C4.014 11.0002 2 8.98621 2 6.50021C2 4.01521 4.014 2.00021 6.5 2.00021C8.985 2.00021 11 4.01521 11 6.50021C11 8.98621 8.985 11.0002 6.5 11.0002Z"
          fill="white"
          fill-opacity="0.9"
        />
      </svg>

    </span>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Body Health Analyzer',
      },
      searchingThing: {
        type: String,
        default: 'Search client',
      },
    },
    data() {
      return {
        searchMode: false,
        searchText: '',
      }
    },
    watch: {
      searchText(val) {
        this.$emit('search', val)
      },
    },
    methods: {
      search() {
        this.searchMode = true
        this.$nextTick(() => {
          this.$refs.refsearch.focus()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}

.mobile-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #212121;
  height: 53px;
  width: 100%;
  align-items: center;
  padding: 0 18px;
  color: #fff;
  font-size: 20px;

  &-menu {
    display: block;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  &-input {
    width: 80%;
    input {
      border: none;
      outline: none;
      font-size: 20px;
      color: #fff;
      opacity: 0.6;
    }
  }

  &-search {
    display: block;
    height: 30px;
    width: 30px;
    padding-top: 5px;
    cursor: pointer;

  }
}

@media (min-width: 1024px) {
  .mobile-head {
    display: none;
  }
}

</style>
